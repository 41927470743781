import { District } from "generated/types";
import { Tag } from "./Tag";

type Props = {
  district: District;
};

export function SyncStatusBadge({ district }: Props) {
  return <Tag district={district}></Tag>;
}
