import { useEffect, useRef } from "react";

export function useDebounce(func: Function, wait: number) {
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [])
    
    return (...args: any[]) => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    
        timeout.current = setTimeout(() => {
            func(...args);
        }, wait);
    };
}