/**
 * Context to provide current logged admin if any
 */
import { LoggedAdminQuery, useLoggedAdminQuery } from "generated/types";
import { createContext, PropsWithChildren, useContext } from "react";

import { AUTH_TOKEN_KEY } from "../config/constants";

type AdminContextType =
  | {
      admin: LoggedAdminQuery["loggedAdmin"] | undefined | null;
      logout: () => void;
    }
  | undefined;

export const adminContext = createContext<AdminContextType | undefined>(
  undefined
);

export function AdminContextProvider({ children }: PropsWithChildren<{}>) {
  const query = useLoggedAdminQuery({
    onCompleted: ({ loggedAdmin }) => {
      console.log(loggedAdmin);
      if (!loggedAdmin) {
        logout();
        return;
      }
    },
    onError: (err) => {
      console.log(err.message);
      logout();
    },
    skip: !window.localStorage.getItem(AUTH_TOKEN_KEY),
  });

  console.log(query.data);

  function logout() {
    window.localStorage.removeItem(AUTH_TOKEN_KEY);
    window.location.reload();
  }

  if (query.loading) {
    return null;
  }

  return (
    <adminContext.Provider
      value={{
        admin: query.data?.loggedAdmin,
        logout,
      }}
    >
      {children}
    </adminContext.Provider>
  );
}

export function useAdminContex() {
  const context = useContext(adminContext);

  if (!context || !context.admin) {
    throw new Error(`Not Authenticated!. User should have been redirected!`);
  }

  return context;
}
