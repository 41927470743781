import moment from "moment";
import { Button, DatePicker, Form, Input, message, Modal } from "antd";
import { useAddProductUpdateMutation } from "generated/types";
import { useHistory } from "react-router-dom";

export function AddProductUpdate() {
  const history = useHistory();
  const [addProductUpdateMutation, { loading }] = useAddProductUpdateMutation({
    onError: () => {
      message.error("Something went wrong");
    },
    onCompleted: () => {
      message.success("Update is saved");
    },
  });
  const [form] = Form.useForm();

  function closeModal() {
    history.goBack();
  }

  async function handleSubmit(values: {
    date: moment.Moment;
    description: string;
    youTubeLink?: string;
  }) {
    const { date, description, youTubeLink } = values;
    await addProductUpdateMutation({
      variables: {
        input: { date: date.valueOf(), description, youTubeLink },
      },
    });
    form.resetFields();
  }

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      title="Add Product Updates"
      okText="Add"
      footer={null}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{ date: moment(), description: "", youtubeLink: "" }}
      >
        <Form.Item name="date" label="Date" rules={[{ required: true }]}>
          <DatePicker defaultValue={moment()} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input.TextArea style={{ height: "100px" }} />
        </Form.Item>
        <Form.Item name="youTubeLink" label="YouTube link id (optional)">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={loading}>
            Add
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
