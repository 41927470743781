import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ObjectId: any;
  Upload: any;
};

export type Accomm = {
  _id: Scalars['ObjectId'];
  category: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  status: Scalars['String'];
  student: Student;
  text: Scalars['String'];
};

export type AccommReport = {
  _id: Scalars['ObjectId'];
  accommodations: Array<Accomm>;
  author: User;
  authorId: Scalars['ObjectId'];
  comment?: Maybe<Scalars['String']>;
  conditions: Array<AccommReportCondition>;
  date: Scalars['Float'];
  section: Section;
  sectionId: Scalars['ObjectId'];
  student: Student;
  studentId: Scalars['ObjectId'];
};

export enum AccommReportCondition {
  OfferedHelpful = 'OfferedHelpful',
  OfferedRefused = 'OfferedRefused',
  OfferedUnhelpful = 'OfferedUnhelpful'
}

export type AccommReportUpdateFieldsInput = {
  accommodationIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  comment?: InputMaybe<Scalars['String']>;
  conditions?: InputMaybe<Array<AccommReportCondition>>;
  date?: InputMaybe<Scalars['Float']>;
};

export type AccommReportsQueryArgs = {
  authorId?: InputMaybe<Scalars['ObjectId']>;
  fromDate: Scalars['Float'];
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  toDate: Scalars['Float'];
};

export type AddProductUpdateInput = {
  date: Scalars['Float'];
  description: Scalars['String'];
  youTubeLink?: InputMaybe<Scalars['String']>;
};

export type AddStudentToOfficeAidInput = {
  schoolOfficeId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type Admin = {
  _id: Scalars['ObjectId'];
  username: Scalars['String'];
};

export type AdminLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AskedResponse = {
  _id: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
  request: Scalars['String'];
  response: Scalars['String'];
  schoolId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type AttendanceReport = {
  _id: Scalars['ObjectId'];
  author: User;
  authorId: Scalars['ObjectId'];
  date: Scalars['Float'];
  section: Section;
  sectionId: Scalars['ObjectId'];
  student: Student;
  studentId: Scalars['ObjectId'];
  type: Scalars['String'];
};

export type AttendanceReportsQueryArgs = {
  authorId?: InputMaybe<Scalars['ObjectId']>;
  fromDate: Scalars['Float'];
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  toDate: Scalars['Float'];
};

/** Max 10 students can be restricted */
export type BathroomRestrictedStudentsOutOfLimitError = {
  message: Scalars['String'];
};

export enum BehaviorPerformance {
  Negative = 'Negative',
  Positive = 'Positive'
}

export type BehaviorReport = {
  _id: Scalars['ObjectId'];
  author: User;
  authorId: Scalars['ObjectId'];
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Float'];
  performance: BehaviorPerformance;
  section: Section;
  sectionId: Scalars['ObjectId'];
  student: Student;
  studentId: Scalars['ObjectId'];
  texts: Array<Scalars['String']>;
};

export type BehaviorReportNotification = {
  _id: Scalars['ObjectId'];
  behaviorReport: BehaviorReport;
  content: Scalars['String'];
  isRead: Scalars['Boolean'];
};

export type BehaviorReportUpdateFields = {
  comment?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Float']>;
  performance?: InputMaybe<BehaviorPerformance>;
  texts: Array<Scalars['String']>;
};

export type BehaviorReportsQueryInput = {
  authorId?: InputMaybe<Scalars['ObjectId']>;
  /** dd-mm-yyyy */
  dateFormatted?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['Float']>;
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  sectionsIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  toDate?: InputMaybe<Scalars['Float']>;
};

export type CombinedSectionReports = {
  author: User;
  date: Scalars['Float'];
  lessonPlan?: Maybe<LessonPlan>;
  reports: Array<SectionReport>;
  section: Section;
};

export type CombinedSectionReportsFilters = {
  afterDate?: InputMaybe<Scalars['Float']>;
  authorId?: InputMaybe<Scalars['String']>;
  beforeDate?: InputMaybe<Scalars['Float']>;
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  withAccommReports?: InputMaybe<Scalars['Boolean']>;
  withAttendanceReports?: InputMaybe<Scalars['Boolean']>;
  withBehaviorReports?: InputMaybe<Scalars['Boolean']>;
  withGoalReports?: InputMaybe<Scalars['Boolean']>;
  withRestroomUsageReports?: InputMaybe<Scalars['Boolean']>;
  withUploadReports?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAccommReportInput = {
  _id: Scalars['ObjectId'];
  accommodationIds: Array<Scalars['ObjectId']>;
  authorId: Scalars['ObjectId'];
  comment: Scalars['String'];
  conditions: Array<AccommReportCondition>;
  date: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type CreateAskedResponseInput = {
  districtId: Scalars['ObjectId'];
  request: Scalars['String'];
  response: Scalars['String'];
  schoolId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type CreateAttendanceReportInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  date: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  type: Scalars['String'];
};

export type CreateBehaviorReportInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['Float'];
  /** dd-mm-yyyy */
  dateFormatted: Scalars['String'];
  performance: BehaviorPerformance;
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  texts: Array<Scalars['String']>;
};

export type CreateDailyPostCommentInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  date: Scalars['Float'];
  postId: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type CreateDailyPostInput = {
  _id: Scalars['ID'];
  authorId: Scalars['ObjectId'];
  body?: InputMaybe<Scalars['String']>;
  creationDate: Scalars['Float'];
  dueDate?: InputMaybe<Scalars['Float']>;
  fileIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  imageIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  publishedDate: Scalars['Float'];
  scopeIds: Array<Scalars['String']>;
  scopeType: DailyPostScopeType;
  subject: Scalars['String'];
  type: DailyPostType;
  videoIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type CreateDistrictEventInput = {
  _id: Scalars['ObjectId'];
  date: Scalars['Float'];
  districtId: Scalars['ObjectId'];
  type: DistrictEventType;
};

export type CreateEventInput = {
  _id: Scalars['ObjectId'];
  allowedPeriods: Array<Scalars['String']>;
  authorId: Scalars['ObjectId'];
  dates: Array<Scalars['Float']>;
  description: Scalars['String'];
  dismissalTime: Scalars['Float'];
  eventStudentsGroupIds: Array<Scalars['ObjectId']>;
  invitedStaffIds: Array<Scalars['ObjectId']>;
  location: Scalars['String'];
  name: Scalars['String'];
  studentIds: Array<Scalars['ObjectId']>;
  studentsInTreeSelect: Array<Scalars['String']>;
  type: EventType;
};

export type CreateGoalReportInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['Float'];
  goalIds: Array<Scalars['ObjectId']>;
  performances: Array<GoalReportPerformanceInput>;
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type CreateHb4545SectionInput = {
  _id: Scalars['ObjectId'];
  allowedPeriodsForHB4545: Array<Scalars['String']>;
  authorId: Scalars['ObjectId'];
  days: Array<Scalars['String']>;
  districtId: Scalars['ObjectId'];
  name: Scalars['String'];
  roomNumber?: InputMaybe<Scalars['String']>;
  schoolId: Scalars['ObjectId'];
  startTime: Scalars['Float'];
  subject: Scalars['String'];
  teacherIds: Array<Scalars['ObjectId']>;
};

export type CreateHb4545SessionInput = {
  _id: Scalars['ObjectId'];
  absentStudentIds: Array<Scalars['ObjectId']>;
  attendedStudentIds: Array<Scalars['ObjectId']>;
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['Float'];
  dateFormatted: Scalars['String'];
  durationInMilliseconds: Scalars['Float'];
  hb4545SectionId: Scalars['ObjectId'];
};

export type CreateHb4545SessionResult = Hb4545Session | Hb4545SessionExistsOnDateError;

export type CreateHallpassInput = {
  _id: Scalars['ObjectId'];
  allowedById: Scalars['ObjectId'];
  allowedTime?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  districtId: Scalars['ObjectId'];
  endDate?: InputMaybe<Scalars['Float']>;
  from: Scalars['String'];
  officeId?: InputMaybe<Scalars['ObjectId']>;
  schoolId: Scalars['ObjectId'];
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  startDate: Scalars['Float'];
  studentId: Scalars['ObjectId'];
  to: Scalars['String'];
  type: HallpassType;
};

export type CreateSchoolEventInput = {
  _id: Scalars['ObjectId'];
  date: Scalars['Float'];
  schoolId: Scalars['ObjectId'];
  type: SchoolEventType;
};

export type CreateSchoolOfficeInput = {
  schoolId: Scalars['ObjectId'];
  staffIds: Array<Scalars['ObjectId']>;
  title: Scalars['String'];
};

/** Result of create school office mutation */
export type CreateSchoolOfficeResult = ForbiddenError | NotFoundError | SchoolOffice | SchoolOfficeUsedTitleError;

export type CreateSemesterInput = {
  _id: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
  name: Scalars['String'];
  schoolId: Scalars['ObjectId'];
  sectionIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type CreateStudentCommentInput = {
  authorId: Scalars['ObjectId'];
  date: Scalars['Float'];
  studentId: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type CreateTeacherAbsentReportInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  date: Scalars['Float'];
  sectionsIds: Array<Scalars['ObjectId']>;
  teacherId: Scalars['ObjectId'];
};

export type CreateUploadReportInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  comment?: InputMaybe<Scalars['String']>;
  date: Scalars['Float'];
  fileIds: Array<Scalars['ObjectId']>;
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type DailyPost = {
  _id: Scalars['ID'];
  author: User;
  body?: Maybe<Scalars['String']>;
  commentsAmount: Scalars['Float'];
  creationDate: Scalars['Float'];
  dueDate?: Maybe<Scalars['Float']>;
  files?: Maybe<Array<File>>;
  images?: Maybe<Array<File>>;
  isCommentedByUser: Scalars['Boolean'];
  isCompletedByUser: Scalars['Boolean'];
  isLikedByUser: Scalars['Boolean'];
  likesAmount: Scalars['Float'];
  publishedDate: Scalars['Float'];
  scopeIds: Array<Scalars['ObjectId']>;
  scopeType: DailyPostScopeType;
  subject: Scalars['String'];
  type: DailyPostType;
  videos?: Maybe<Array<File>>;
};

export type DailyPostComment = {
  _id: Scalars['ID'];
  author: User;
  date: Scalars['Float'];
  isEdited?: Maybe<Scalars['Boolean']>;
  postId: Scalars['ID'];
  text: Scalars['String'];
};

export enum DailyPostScopeType {
  District = 'District',
  Me = 'Me',
  School = 'School',
  Staff = 'Staff'
}

export enum DailyPostType {
  Regular = 'Regular',
  Task = 'Task'
}

export type DeleteAttendanceReportInput = {
  date: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};

export type DeleteHb4545SessionResult = ForbiddenError | Hb4545Session | Hb4545SessionNotFoundError;

/** Result of delete school office mutation */
export type DeleteSchoolOfficeResult = ForbiddenError | NotFoundError | SchoolOffice;

export type District = {
  _id: Scalars['ID'];
  classlinkId?: Maybe<Scalars['String']>;
  cleverId?: Maybe<Scalars['String']>;
  gradingPeriods?: Maybe<Array<GradingPeriod>>;
  lastSynced?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  sectionPeriods: Array<Scalars['String']>;
  syncFailureMessage?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<DistrictSyncStatus>;
};

export type DistrictEvent = {
  _id: Scalars['ObjectId'];
  date: Scalars['Float'];
  type: DistrictEventType;
};

export enum DistrictEventType {
  EarlyRelease = 'EarlyRelease',
  StaffAndStudentHolidays = 'StaffAndStudentHolidays',
  StateTestingDates = 'StateTestingDates',
  TeacherWorkDay = 'TeacherWorkDay'
}

export type DistrictSyncInput = {
  _id: Scalars['ID'];
  classlinkId?: InputMaybe<Scalars['String']>;
  cleverId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export enum DistrictSyncStatus {
  Failed = 'failed',
  Loading = 'loading',
  NotSynced = 'notSynced',
  Synced = 'synced'
}

export enum EocSubject {
  English = 'English',
  Math = 'Math',
  Science = 'Science',
  SocialStudies = 'SocialStudies'
}

export type EditDailyPostCommentInput = {
  _id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type EditSchoolOfficeInput = {
  _id: Scalars['ObjectId'];
  staffIds: Array<Scalars['ObjectId']>;
  title: Scalars['String'];
};

/** Result of edit school office mutation */
export type EditSchoolOfficeResult = ForbiddenError | NotFoundError | SchoolOffice | SchoolOfficeUsedTitleError;

export type Event = {
  _id: Scalars['ObjectId'];
  allowedPeriods: Array<Scalars['String']>;
  author: User;
  dates: Array<Scalars['Float']>;
  description: Scalars['String'];
  dismissalTime: Scalars['Float'];
  eventStudentsGroups?: Maybe<Array<EventStudentsGroup>>;
  isUserPending: Scalars['Boolean'];
  location: Scalars['String'];
  name: Scalars['String'];
  pendingStaffs: Array<User>;
  staffs: Array<User>;
  state: Scalars['String'];
  students: Array<Student>;
  studentsInTreeSelect: Array<Scalars['String']>;
  type: EventType;
};

export type EventAttendanceReport = {
  _id: Scalars['ID'];
  author: User;
  date: Scalars['Float'];
  event: Event;
  student: Student;
  type: Scalars['String'];
};

export type EventAttendanceReportsQueryInput = {
  date: Scalars['Float'];
  eventId: Scalars['ObjectId'];
};

export type EventInvitationNotification = {
  _id: Scalars['ObjectId'];
  by: Scalars['ObjectId'];
  event: Event;
  to: Scalars['ObjectId'];
};

export type EventStudentsGroup = {
  _id: Scalars['ObjectId'];
  author: User;
  name: Scalars['String'];
  students: Array<Student>;
};

export enum EventType {
  Event = 'Event',
  Service = 'Service'
}

export type EventsQueryInput = {
  firstDate: Scalars['Float'];
  lastDate: Scalars['Float'];
  userId: Scalars['ObjectId'];
};

export type File = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  isUsed: Scalars['Boolean'];
  key: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type Filter = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  textSearch?: InputMaybe<Scalars['String']>;
};

export type ForbiddenError = {
  message: Scalars['String'];
};

export type Goal = {
  _id: Scalars['ID'];
  calcType: GoalCalcType;
  category: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  student: Student;
  text: Scalars['String'];
};

export enum GoalCalcType {
  OutOf = 'outOf',
  Percentage = 'percentage'
}

export type GoalReport = {
  _id: Scalars['ObjectId'];
  author: User;
  authorId: Scalars['ObjectId'];
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Float'];
  goals: Array<Goal>;
  performances: Array<GoalReportPerformance>;
  section: Section;
  sectionId: Scalars['ObjectId'];
  student: Student;
  studentId: Scalars['ObjectId'];
};

export type GoalReportPerformance = {
  of: Scalars['Float'];
  out: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type GoalReportPerformanceInput = {
  of: Scalars['Float'];
  out: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type GoalReportUpdateFieldsInput = {
  comment?: InputMaybe<Scalars['String']>;
  goalIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  performances: Array<GoalReportPerformanceInput>;
};

export type GoalReportsQueryInput = {
  authorId?: InputMaybe<Scalars['ObjectId']>;
  fromDate: Scalars['Float'];
  goalIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  toDate: Scalars['Float'];
};

export type GradingPeriod = {
  end: Scalars['Float'];
  pr: Scalars['Float'];
  semesterId?: Maybe<Scalars['ObjectId']>;
  start: Scalars['Float'];
};

export type Hb4545Session = {
  _id: Scalars['ObjectId'];
  absentStudents: Array<Student>;
  attendedStudents: Array<Student>;
  author: User;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Float'];
  dateFormatted: Scalars['String'];
  durationInMilliseconds: Scalars['Float'];
  hb4545Section: Section;
};

export type Hb4545SessionExistsOnDateError = {
  dateFormatted: Scalars['String'];
  hb4545SectionId: Scalars['ObjectId'];
  message: Scalars['String'];
};

export type Hb4545SessionNotFoundError = {
  hb4545SessionId: Scalars['ObjectId'];
  message: Scalars['String'];
};

export type Hb4545SessionQueryInput = {
  dateFormatted: Scalars['String'];
  sectionId: Scalars['ObjectId'];
};

export type Hb4545StudentReport = {
  absentAmount: Scalars['Int'];
  attendanceAmount: Scalars['Int'];
  attendedSessionsDurations: Scalars['Int'];
  section: Section;
  subject: Scalars['String'];
  totalSessionsDurations: Scalars['Int'];
};

export type Hallpass = {
  _id: Scalars['ObjectId'];
  allowedBy?: Maybe<User>;
  allowedTime?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  destinationIsUser: Scalars['Boolean'];
  districtId: Scalars['ObjectId'];
  endDate?: Maybe<Scalars['Float']>;
  from: Scalars['String'];
  officeId?: Maybe<Scalars['ObjectId']>;
  schoolId: Scalars['ObjectId'];
  sentBackDate?: Maybe<Scalars['Float']>;
  startDate: Scalars['Float'];
  student: Student;
  to: HallpassDestionationResult;
  type: Scalars['String'];
};

/** Union result of save restricted students mutation. */
export type HallpassDestionationResult = Event | OtherDestination | SchoolOffice | Section | User;

export type HallpassPolicy = {
  _id: Scalars['ObjectId'];
  bathroomLimit?: Maybe<Scalars['Int']>;
  bathroomRestrictedStudentIds: Array<Scalars['ObjectId']>;
  studentId: Scalars['ObjectId'];
  updatedBy?: Maybe<Scalars['ObjectId']>;
};

export enum HallpassType {
  Bathroom = 'Bathroom',
  Event = 'Event',
  Hb4545Section = 'HB4545Section',
  NurseMeeting = 'NurseMeeting',
  OfficeStaffMeeting = 'OfficeStaffMeeting',
  SchoolOffice = 'SchoolOffice',
  TeacherMeeting = 'TeacherMeeting'
}

export type InProgressHallpassQueryArgs = {
  date: Scalars['Float'];
  studentId: Scalars['ObjectId'];
};

export type InviteUserInput = {
  districtId: Scalars['ObjectId'];
  email: Scalars['String'];
  schoolId?: InputMaybe<Scalars['ObjectId']>;
};

export type LessonPlan = {
  _id: Scalars['ObjectId'];
  date: Scalars['Float'];
  description: Scalars['String'];
  section: Section;
  sectionId: Scalars['ObjectId'];
};

export type LessonPlanQueryArgs = {
  date: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
};

export type Mutation = {
  acceptEventInvitation: Scalars['Boolean'];
  addDistrictAdmin: Scalars['Boolean'];
  addDistrictAdminAssistant: Scalars['Boolean'];
  addDistrictAthleticDirector: Scalars['Boolean'];
  addDistrictAthleticDirectorAssistant: Scalars['Boolean'];
  addDistrictCoordinator: Scalars['Boolean'];
  addDistrictHandler: Scalars['Boolean'];
  addDistrictSpEdAdmin: Scalars['Boolean'];
  addDistrictSpEdAdminAssistant: Scalars['Boolean'];
  addDistrictSpecialist: Scalars['Boolean'];
  addHB4545Teacher: Scalars['Boolean'];
  addProductUpdate: ProductUpdate;
  addSchoolCounselor: Scalars['Boolean'];
  addSchoolPrincipal: Scalars['Boolean'];
  addSchoolPrincipalAssistant: Scalars['Boolean'];
  addSchoolSpEdAdmin: Scalars['Boolean'];
  addSchoolSpEdAdminAssistant: Scalars['Boolean'];
  addSchoolSpEdCaseManager: Scalars['Boolean'];
  addStudentMentor: Scalars['Boolean'];
  addStudentToOfficeAid: Scalars['Boolean'];
  addStudentsToHB4545Section: Scalars['Boolean'];
  approveStudentMentorRoleRequest: Scalars['Boolean'];
  archiveGradingPeriods: Scalars['Boolean'];
  classlinkAuth: Scalars['String'];
  cleverAuth: Scalars['String'];
  createAccommReport: AccommReport;
  createAttendanceReport: AttendanceReport;
  createBehaviorReport: BehaviorReport;
  createDailyPost: DailyPost;
  createDailyPostComment: DailyPostComment;
  createDistrictEvents: Scalars['Boolean'];
  createEvent: Event;
  createGoalReport: GoalReport;
  createHB4545Section: Scalars['Boolean'];
  createHB4545Session: CreateHb4545SessionResult;
  createHallpass: Hallpass;
  createSchoolEvents: Scalars['Boolean'];
  createSchoolOffice: CreateSchoolOfficeResult;
  createSemester: Scalars['Boolean'];
  createStudentComment: Scalars['Boolean'];
  createTeacherAbsentReport: Scalars['Boolean'];
  createUploadReport: UploadReport;
  createUserFeedback: Scalars['Boolean'];
  deleteAccommReport: Scalars['Boolean'];
  deleteAskEdResponse: Scalars['Boolean'];
  deleteAttendanceReport: Scalars['Boolean'];
  deleteBehaviorReport: Scalars['Boolean'];
  deleteDailyPost: Scalars['Boolean'];
  deleteDailyPostComment: Scalars['Boolean'];
  deleteDistrictEvents: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  deleteEventStudentsGroup: Scalars['Boolean'];
  deleteFile: Scalars['Boolean'];
  deleteGoalReport: Scalars['Boolean'];
  deleteHB4545Section: Scalars['Boolean'];
  deleteHB4545Session: DeleteHb4545SessionResult;
  deleteHallpass: Scalars['Boolean'];
  deleteSchoolEvents: Scalars['Boolean'];
  deleteSchoolOffice: DeleteSchoolOfficeResult;
  deleteSemester: Scalars['Boolean'];
  deleteUploadReport: Scalars['Boolean'];
  editDailyPostComment: DailyPostComment;
  editSchoolOffice: EditSchoolOfficeResult;
  enableAskEdToSchool: Scalars['Boolean'];
  googleAuth: Scalars['String'];
  grantSchoolMentorRole: Scalars['Boolean'];
  inviteUser: Scalars['Boolean'];
  leaveStudentMentorRole: Scalars['Boolean'];
  loginAdmin: Scalars['String'];
  markNotificationAsRead: Scalars['Boolean'];
  microsoftAuth: Scalars['String'];
  nativeAuth: Scalars['String'];
  registerUser: Scalars['String'];
  rejectEventInvitation: Scalars['Boolean'];
  rejectStudentMentorRoleRequest: Scalars['Boolean'];
  removeDistrictAdmin: Scalars['Boolean'];
  removeDistrictAdminAssistant: Scalars['Boolean'];
  removeDistrictAthleticDirector: Scalars['Boolean'];
  removeDistrictAthleticDirectorAssistant: Scalars['Boolean'];
  removeDistrictCoordinator: Scalars['Boolean'];
  removeDistrictHandler: Scalars['Boolean'];
  removeDistrictSpEdAdmin: Scalars['Boolean'];
  removeDistrictSpEdAdminAssistant: Scalars['Boolean'];
  removeDistrictSpecialist: Scalars['Boolean'];
  removeHB4545Teacher: Scalars['Boolean'];
  removeSchoolCounselor: Scalars['Boolean'];
  removeSchoolMentorRole: Scalars['Boolean'];
  removeSchoolPrincipal: Scalars['Boolean'];
  removeSchoolPrincipalAssistant: Scalars['Boolean'];
  removeSchoolSpEdAdmin: Scalars['Boolean'];
  removeSchoolSpEdAdminAssistant: Scalars['Boolean'];
  removeSchoolSpEdCaseManager: Scalars['Boolean'];
  removeStudentFromHB4545Section: Scalars['Boolean'];
  removeStudentFromOfficeAid: Scalars['Boolean'];
  removeStudentMentor: Scalars['Boolean'];
  removeTeacherAbsentReport: Scalars['Boolean'];
  reportUnauthorizedStudentInHallway: Scalars['Boolean'];
  requestStudentMentorRole: Scalars['Boolean'];
  saveAskedResponse: Scalars['Boolean'];
  saveBathroomLimit: SaveBathroomLimitResult;
  saveBathroomRestrictedStudents: SaveBathroomRestrictedStudentsResult;
  sendBackInProgressHallpass: Hallpass;
  setGradingPeriods: Scalars['Boolean'];
  setSchoolPeriod: Scalars['Boolean'];
  setSections: Scalars['Boolean'];
  setStudentFailedEOCs: Scalars['Boolean'];
  setStudentsFilters: Scalars['Boolean'];
  startDistrictSync: Scalars['Boolean'];
  stopInProgressHallpass: Hallpass;
  toggleDailyPostComplete: Scalars['Boolean'];
  toggleDailyPostLike: Scalars['Boolean'];
  updateAccommReport: AccommReport;
  updateBehaviorReport: BehaviorReport;
  updateDailyPost: DailyPost;
  updateEvent: Event;
  updateGoalReport: GoalReport;
  updateHB4545Section: Scalars['Boolean'];
  updateHB4545Session: UpdateHb4545Session;
  updateSectionStudentVisibility: Scalars['Boolean'];
  updateSections: Scalars['Boolean'];
  updateSemester: Scalars['Boolean'];
  updateStudentType: Scalars['Boolean'];
  uploadFile: File;
  upsertAccomms: Scalars['Boolean'];
  upsertEventAttendanceReport: EventAttendanceReport;
  upsertEventStudentsGroup: EventStudentsGroup;
  upsertGoals: Scalars['Boolean'];
  upsertLessonPlan: LessonPlan;
};


export type MutationAcceptEventInvitationArgs = {
  eventId: Scalars['ObjectId'];
};


export type MutationAddDistrictAdminArgs = {
  districtAdminEmail: Scalars['String'];
  districtId: Scalars['ObjectId'];
};


export type MutationAddDistrictAdminAssistantArgs = {
  districtAdminAssistantEmail: Scalars['String'];
  districtId: Scalars['ObjectId'];
};


export type MutationAddDistrictAthleticDirectorArgs = {
  districtAthleticDirectorEmail: Scalars['String'];
  districtId: Scalars['ObjectId'];
};


export type MutationAddDistrictAthleticDirectorAssistantArgs = {
  districtAthleticDirectorAssistantEmail: Scalars['String'];
  districtId: Scalars['ObjectId'];
};


export type MutationAddDistrictCoordinatorArgs = {
  districtCoordinatorEmail: Scalars['String'];
  districtId: Scalars['ObjectId'];
};


export type MutationAddDistrictHandlerArgs = {
  districtId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddDistrictSpEdAdminArgs = {
  districtId: Scalars['ObjectId'];
  districtSpEdAdminEmail: Scalars['String'];
};


export type MutationAddDistrictSpEdAdminAssistantArgs = {
  districtId: Scalars['ObjectId'];
  districtSpEdAdminAssistantEmail: Scalars['String'];
};


export type MutationAddDistrictSpecialistArgs = {
  districtId: Scalars['ObjectId'];
  districtSpecialistEmail: Scalars['String'];
};


export type MutationAddHb4545TeacherArgs = {
  teacherId: Scalars['ObjectId'];
};


export type MutationAddProductUpdateArgs = {
  input: AddProductUpdateInput;
};


export type MutationAddSchoolCounselorArgs = {
  schoolId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddSchoolPrincipalArgs = {
  schoolId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddSchoolPrincipalAssistantArgs = {
  schoolId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddSchoolSpEdAdminArgs = {
  schoolId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddSchoolSpEdAdminAssistantArgs = {
  schoolId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddSchoolSpEdCaseManagerArgs = {
  schoolId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
};


export type MutationAddStudentMentorArgs = {
  studentId: Scalars['ObjectId'];
};


export type MutationAddStudentToOfficeAidArgs = {
  input: AddStudentToOfficeAidInput;
};


export type MutationAddStudentsToHb4545SectionArgs = {
  sectionId: Scalars['ObjectId'];
  studentIds: Array<Scalars['ObjectId']>;
};


export type MutationApproveStudentMentorRoleRequestArgs = {
  notificationId: Scalars['ObjectId'];
};


export type MutationArchiveGradingPeriodsArgs = {
  districtId: Scalars['ObjectId'];
};


export type MutationClasslinkAuthArgs = {
  code: Scalars['String'];
};


export type MutationCleverAuthArgs = {
  redirectURI: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCreateAccommReportArgs = {
  input: CreateAccommReportInput;
};


export type MutationCreateAttendanceReportArgs = {
  input: CreateAttendanceReportInput;
};


export type MutationCreateBehaviorReportArgs = {
  input: CreateBehaviorReportInput;
};


export type MutationCreateDailyPostArgs = {
  input: CreateDailyPostInput;
};


export type MutationCreateDailyPostCommentArgs = {
  input: CreateDailyPostCommentInput;
};


export type MutationCreateDistrictEventsArgs = {
  input: Array<CreateDistrictEventInput>;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateGoalReportArgs = {
  input: CreateGoalReportInput;
};


export type MutationCreateHb4545SectionArgs = {
  input: CreateHb4545SectionInput;
};


export type MutationCreateHb4545SessionArgs = {
  input: CreateHb4545SessionInput;
};


export type MutationCreateHallpassArgs = {
  input: CreateHallpassInput;
};


export type MutationCreateSchoolEventsArgs = {
  input: Array<CreateSchoolEventInput>;
};


export type MutationCreateSchoolOfficeArgs = {
  input: CreateSchoolOfficeInput;
};


export type MutationCreateSemesterArgs = {
  input: CreateSemesterInput;
};


export type MutationCreateStudentCommentArgs = {
  input: CreateStudentCommentInput;
};


export type MutationCreateTeacherAbsentReportArgs = {
  input: CreateTeacherAbsentReportInput;
};


export type MutationCreateUploadReportArgs = {
  input: CreateUploadReportInput;
};


export type MutationCreateUserFeedbackArgs = {
  input: UserFeedbackInput;
};


export type MutationDeleteAccommReportArgs = {
  accommReportId: Scalars['ObjectId'];
};


export type MutationDeleteAskEdResponseArgs = {
  responseId: Scalars['ObjectId'];
};


export type MutationDeleteAttendanceReportArgs = {
  input: DeleteAttendanceReportInput;
};


export type MutationDeleteBehaviorReportArgs = {
  behaviorReportId: Scalars['ObjectId'];
};


export type MutationDeleteDailyPostArgs = {
  dailyPostId: Scalars['ObjectId'];
};


export type MutationDeleteDailyPostCommentArgs = {
  commentId: Scalars['ObjectId'];
};


export type MutationDeleteDistrictEventsArgs = {
  districtEventIds: Array<Scalars['ObjectId']>;
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['ObjectId'];
};


export type MutationDeleteEventStudentsGroupArgs = {
  eventStudentsGroupId: Scalars['ObjectId'];
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['ObjectId'];
};


export type MutationDeleteGoalReportArgs = {
  goalReportId: Scalars['ObjectId'];
};


export type MutationDeleteHb4545SectionArgs = {
  sectionId: Scalars['ObjectId'];
};


export type MutationDeleteHb4545SessionArgs = {
  sessionId: Scalars['ObjectId'];
};


export type MutationDeleteHallpassArgs = {
  hallpassId: Scalars['String'];
};


export type MutationDeleteSchoolEventsArgs = {
  schoolEventIds: Array<Scalars['ObjectId']>;
};


export type MutationDeleteSchoolOfficeArgs = {
  schoolOfficeId: Scalars['ObjectId'];
};


export type MutationDeleteSemesterArgs = {
  semesterId: Scalars['ObjectId'];
};


export type MutationDeleteUploadReportArgs = {
  uploadReportId: Scalars['ObjectId'];
};


export type MutationEditDailyPostCommentArgs = {
  input: EditDailyPostCommentInput;
};


export type MutationEditSchoolOfficeArgs = {
  input: EditSchoolOfficeInput;
};


export type MutationEnableAskEdToSchoolArgs = {
  schoolId: Scalars['ObjectId'];
};


export type MutationGoogleAuthArgs = {
  idToken: Scalars['String'];
};


export type MutationGrantSchoolMentorRoleArgs = {
  schoolId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationLeaveStudentMentorRoleArgs = {
  studentId: Scalars['ObjectId'];
};


export type MutationLoginAdminArgs = {
  input: AdminLoginInput;
};


export type MutationMarkNotificationAsReadArgs = {
  notificationId: Scalars['ObjectId'];
};


export type MutationMicrosoftAuthArgs = {
  token: Scalars['String'];
};


export type MutationNativeAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRejectEventInvitationArgs = {
  eventId: Scalars['ObjectId'];
};


export type MutationRejectStudentMentorRoleRequestArgs = {
  notificationId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictAdminArgs = {
  districtAdminId: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictAdminAssistantArgs = {
  districtAdminAssistantId: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictAthleticDirectorArgs = {
  districtAthleticDirectorId: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictAthleticDirectorAssistantArgs = {
  districtAthleticDirectorAssistantId: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictCoordinatorArgs = {
  districtCoordinatorId: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictHandlerArgs = {
  districtHandlerId: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictSpEdAdminArgs = {
  districtId: Scalars['ObjectId'];
  districtSpEdAdminId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictSpEdAdminAssistantArgs = {
  districtId: Scalars['ObjectId'];
  districtSpEdAdminAssistantId: Scalars['ObjectId'];
};


export type MutationRemoveDistrictSpecialistArgs = {
  districtId: Scalars['ObjectId'];
  districtSpecialistId: Scalars['ObjectId'];
};


export type MutationRemoveHb4545TeacherArgs = {
  schoolId: Scalars['ObjectId'];
  teacherId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolCounselorArgs = {
  schoolCounselorId: Scalars['ObjectId'];
  schoolId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolMentorRoleArgs = {
  schoolId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolPrincipalArgs = {
  schoolId: Scalars['ObjectId'];
  schoolPrincipalId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolPrincipalAssistantArgs = {
  schoolId: Scalars['ObjectId'];
  schoolPrincipalAssistantId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolSpEdAdminArgs = {
  schoolId: Scalars['ObjectId'];
  schoolSpEdAdminId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolSpEdAdminAssistantArgs = {
  schoolId: Scalars['ObjectId'];
  schoolSpEdAdminAssistantId: Scalars['ObjectId'];
};


export type MutationRemoveSchoolSpEdCaseManagerArgs = {
  schoolId: Scalars['ObjectId'];
  schoolSpEdCaseManagerId: Scalars['ObjectId'];
};


export type MutationRemoveStudentFromHb4545SectionArgs = {
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type MutationRemoveStudentFromOfficeAidArgs = {
  schoolOfficeId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type MutationRemoveStudentMentorArgs = {
  mentorId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
};


export type MutationRemoveTeacherAbsentReportArgs = {
  endOfDay: Scalars['Float'];
  startOfDay: Scalars['Float'];
};


export type MutationReportUnauthorizedStudentInHallwayArgs = {
  location: Scalars['String'];
  studentId: Scalars['ObjectId'];
};


export type MutationRequestStudentMentorRoleArgs = {
  studentId: Scalars['ObjectId'];
};


export type MutationSaveAskedResponseArgs = {
  input: CreateAskedResponseInput;
};


export type MutationSaveBathroomLimitArgs = {
  limit: Scalars['Int'];
  studentId: Scalars['ObjectId'];
};


export type MutationSaveBathroomRestrictedStudentsArgs = {
  restrictedStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  studentId: Scalars['ObjectId'];
};


export type MutationSendBackInProgressHallpassArgs = {
  hallpassId: Scalars['ObjectId'];
};


export type MutationSetGradingPeriodsArgs = {
  districtId: Scalars['ObjectId'];
  gradingPeriods: Array<SetGradingPeriodsInput>;
};


export type MutationSetSchoolPeriodArgs = {
  schoolId: Scalars['ObjectId'];
  sectionPeriods: Array<SchoolPeriodsInput>;
};


export type MutationSetSectionsArgs = {
  sectionIds: Array<Scalars['ObjectId']>;
  semesterId: Scalars['ObjectId'];
};


export type MutationSetStudentFailedEoCsArgs = {
  failedEOCs: Array<EocSubject>;
  studentId: Scalars['ObjectId'];
};


export type MutationSetStudentsFiltersArgs = {
  schoolId: Scalars['ObjectId'];
  studentsFilters: StudentsFiltersInput;
};


export type MutationStartDistrictSyncArgs = {
  district: DistrictSyncInput;
};


export type MutationStopInProgressHallpassArgs = {
  input: StopInProgressHallpassInput;
};


export type MutationToggleDailyPostCompleteArgs = {
  dailyPostId: Scalars['ObjectId'];
};


export type MutationToggleDailyPostLikeArgs = {
  dailyPostId: Scalars['ObjectId'];
};


export type MutationUpdateAccommReportArgs = {
  input: UpdateAccommReportInput;
};


export type MutationUpdateBehaviorReportArgs = {
  input: UpdateBehaviorReportInput;
};


export type MutationUpdateDailyPostArgs = {
  input: UpdateDailyPostInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateGoalReportArgs = {
  input: UpdateGoalReportInput;
};


export type MutationUpdateHb4545SectionArgs = {
  input: UpdateHb4545SectionInput;
};


export type MutationUpdateHb4545SessionArgs = {
  input: UpdateHb4545SessionInput;
  sessionId: Scalars['ObjectId'];
};


export type MutationUpdateSectionStudentVisibilityArgs = {
  input: UpdateSectionStudentVisibilityInput;
};


export type MutationUpdateSectionsArgs = {
  input: UpdateSectionsInput;
};


export type MutationUpdateSemesterArgs = {
  input: UpdateSemesterInput;
  semesterId: Scalars['ObjectId'];
};


export type MutationUpdateStudentTypeArgs = {
  input: UpdateStudentTypeInput;
  schoolId: Scalars['ObjectId'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUpsertAccommsArgs = {
  input: Array<UpsertAccommInput>;
};


export type MutationUpsertEventAttendanceReportArgs = {
  input: UpsertEventAttendanceReportInput;
};


export type MutationUpsertEventStudentsGroupArgs = {
  input: UpsertEventStudentsGroupInput;
};


export type MutationUpsertGoalsArgs = {
  input: Array<UpsertGoalsInput>;
};


export type MutationUpsertLessonPlanArgs = {
  input: UpsertLessonPlanInput;
};

export type NewSchoolOfficeAppointment = {
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  isRead: Scalars['Boolean'];
  title: Scalars['String'];
};

export type NotFoundError = {
  message: Scalars['String'];
  resourceIds: Array<Scalars['ObjectId']>;
};

export type Notification = BehaviorReportNotification | EventInvitationNotification | NewSchoolOfficeAppointment | RequestMentorRoleNotification | UnauthorizedStudentInHallwayNotification;

export type OtherDestination = {
  title: Scalars['String'];
};

export type PageOnboarding = {
  introVideoURL?: Maybe<Scalars['String']>;
  pageMatchPath?: Maybe<Scalars['String']>;
  pageName: Scalars['String'];
  workflows: Array<PageWorkflow>;
};

export type PageWorkflow = {
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ProductUpdate = {
  _id: Scalars['ObjectId'];
  date: Scalars['Float'];
  description: Scalars['String'];
  youTubeLink?: Maybe<Scalars['String']>;
};

export type Query = {
  InProgressHallpasses?: Maybe<Array<Hallpass>>;
  accommReports?: Maybe<Array<AccommReport>>;
  allConnectedDistricts: Array<District>;
  allSchools: Array<School>;
  askedResponses: Array<AskedResponse>;
  attendanceReports: Array<AttendanceReport>;
  behaviorReport: BehaviorReport;
  behaviorReports?: Maybe<Array<BehaviorReport>>;
  classlinkClientId: Scalars['String'];
  cleverClientId: Scalars['String'];
  combinedSectionReports?: Maybe<Array<CombinedSectionReports>>;
  dailyPost: DailyPost;
  dailyPostComments: Array<DailyPostComment>;
  dailyPosts?: Maybe<Array<DailyPost>>;
  district: District;
  districtAdmin?: Maybe<User>;
  districtAdminAssistants?: Maybe<Array<User>>;
  districtAthleticDirector?: Maybe<User>;
  districtAthleticDirectorAssistants?: Maybe<Array<User>>;
  districtCoordinators?: Maybe<Array<User>>;
  districtEvents?: Maybe<Array<DistrictEvent>>;
  districtHandler?: Maybe<User>;
  districtSchools: Array<School>;
  districtSemesters: Array<Semester>;
  districtSpEdAdmin?: Maybe<User>;
  districtSpEdAdminAssistants?: Maybe<Array<User>>;
  districtSpecialists?: Maybe<Array<User>>;
  event: Event;
  eventAttendanceReports?: Maybe<Array<EventAttendanceReport>>;
  eventStudentsGroups?: Maybe<Array<EventStudentsGroup>>;
  events?: Maybe<Array<Event>>;
  failedStudentsForEOC?: Maybe<Array<Student>>;
  getSchoolSectionPeriods: Array<SchoolPeriod>;
  goalReports?: Maybe<Array<GoalReport>>;
  goals?: Maybe<Array<Goal>>;
  hallpassPolicy: HallpassPolicy;
  hb4545Sections?: Maybe<Array<Section>>;
  hb4545Session?: Maybe<Hb4545Session>;
  hb4545Sessions: Array<Hb4545Session>;
  hb4545Students?: Maybe<Array<Student>>;
  inProgressHallpass?: Maybe<Hallpass>;
  inProgressHallpassesByOfficeId?: Maybe<Array<Hallpass>>;
  inProgressHallpassesBySectionsIds?: Maybe<Array<Hallpass>>;
  lessonPlan?: Maybe<LessonPlan>;
  loggedAdmin?: Maybe<Admin>;
  loggedUser?: Maybe<User>;
  negativeBehaviorTexts: Array<Scalars['String']>;
  notifications?: Maybe<Array<Notification>>;
  officeAidStudents: Array<Student>;
  pageOnboarding?: Maybe<PageOnboarding>;
  pendingMentorRoleRequests?: Maybe<Array<RequestMentorRoleNotification>>;
  positiveBehaviorTexts: Array<Scalars['String']>;
  productUpdates: Array<ProductUpdate>;
  recentHallpasses?: Maybe<Array<Hallpass>>;
  reportStatsPerType: Array<ReportStatsPerType>;
  school: School;
  schoolAdministrativeStaffs?: Maybe<Array<User>>;
  schoolCounselors?: Maybe<Array<User>>;
  schoolEvents?: Maybe<Array<SchoolEvent>>;
  schoolOffice?: Maybe<SchoolOffice>;
  schoolOffices: Array<SchoolOffice>;
  schoolPrincipal?: Maybe<User>;
  schoolPrincipalAssistants?: Maybe<Array<User>>;
  schoolSemesters: Array<Semester>;
  schoolSpEdAdmin?: Maybe<User>;
  schoolSpEdAdminAssistants?: Maybe<Array<User>>;
  schoolSpEdCaseManagers?: Maybe<Array<User>>;
  schoolTeachers: Array<User>;
  schools?: Maybe<Array<School>>;
  section: Section;
  sectionHiddenStudents: Array<SectionHiddenStudent>;
  sectionReports?: Maybe<Array<SectionReport>>;
  sectionsByPeriod: Array<Section>;
  semester: Semester;
  student: Student;
  studentBathroomUsage: StudentBathroomUsage;
  studentSectionAttendanceReports?: Maybe<Array<AttendanceReport>>;
  studentTeachers: Array<User>;
  students?: Maybe<Array<Student>>;
  teacherAbsentReports?: Maybe<Array<TeacherAbsentReport>>;
  teacherSections: Array<Section>;
  unreadNotificationsCount: Scalars['Float'];
  uploadReports: Array<UploadReport>;
  user?: Maybe<User>;
  userInvitation: UserInvitation;
  users: Array<User>;
};


export type QueryInProgressHallpassesArgs = {
  sectionId?: InputMaybe<Scalars['ObjectId']>;
};


export type QueryAccommReportsArgs = {
  args: AccommReportsQueryArgs;
};


export type QueryAllSchoolsArgs = {
  filters?: InputMaybe<Filter>;
};


export type QueryAskedResponsesArgs = {
  userId: Scalars['ObjectId'];
};


export type QueryAttendanceReportsArgs = {
  attendanceReportsQueryArgs: AttendanceReportsQueryArgs;
};


export type QueryBehaviorReportArgs = {
  behaviorReportId: Scalars['ObjectId'];
};


export type QueryBehaviorReportsArgs = {
  input: BehaviorReportsQueryInput;
};


export type QueryCombinedSectionReportsArgs = {
  filters: CombinedSectionReportsFilters;
  studentId: Scalars['ObjectId'];
  timezone: Scalars['String'];
};


export type QueryDailyPostArgs = {
  postId: Scalars['ID'];
};


export type QueryDailyPostCommentsArgs = {
  dailyPostId: Scalars['ObjectId'];
};


export type QueryDailyPostsArgs = {
  filter: Filter;
};


export type QueryDistrictArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictAdminArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictAdminAssistantsArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictAthleticDirectorArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictAthleticDirectorAssistantsArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictCoordinatorsArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictEventsArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictHandlerArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictSchoolsArgs = {
  districtId: Scalars['ObjectId'];
  filter?: InputMaybe<Filter>;
};


export type QueryDistrictSemestersArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictSpEdAdminArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictSpEdAdminAssistantsArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryDistrictSpecialistsArgs = {
  districtId: Scalars['ObjectId'];
};


export type QueryEventArgs = {
  eventId: Scalars['ObjectId'];
};


export type QueryEventAttendanceReportsArgs = {
  input: EventAttendanceReportsQueryInput;
};


export type QueryEventStudentsGroupsArgs = {
  authorId: Scalars['ObjectId'];
};


export type QueryEventsArgs = {
  input: EventsQueryInput;
};


export type QueryFailedStudentsForEocArgs = {
  schoolId: Scalars['ObjectId'];
  subject: Scalars['String'];
};


export type QueryGetSchoolSectionPeriodsArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QueryGoalReportsArgs = {
  input: GoalReportsQueryInput;
};


export type QueryGoalsArgs = {
  studentId: Scalars['ID'];
};


export type QueryHallpassPolicyArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryHb4545SessionArgs = {
  input: Hb4545SessionQueryInput;
};


export type QueryHb4545SessionsArgs = {
  sectionId: Scalars['ObjectId'];
};


export type QueryHb4545StudentsArgs = {
  studentIds: Array<Scalars['ObjectId']>;
};


export type QueryInProgressHallpassArgs = {
  args: InProgressHallpassQueryArgs;
};


export type QueryInProgressHallpassesByOfficeIdArgs = {
  officeId: Scalars['ObjectId'];
};


export type QueryInProgressHallpassesBySectionsIdsArgs = {
  sectionsIds: Array<Scalars['ObjectId']>;
};


export type QueryLessonPlanArgs = {
  args: LessonPlanQueryArgs;
};


export type QueryOfficeAidStudentsArgs = {
  schoolOfficeId: Scalars['ObjectId'];
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryPageOnboardingArgs = {
  pagePath: Scalars['String'];
};


export type QueryRecentHallpassesArgs = {
  recentHallpassesQueryArgs: RecentHallpassesInput;
};


export type QueryReportStatsPerTypeArgs = {
  reportType: Scalars['String'];
  studentId: Scalars['ObjectId'];
};


export type QuerySchoolArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolAdministrativeStaffsArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolCounselorsArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolEventsArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolOfficeArgs = {
  schoolOfficeId: Scalars['ObjectId'];
};


export type QuerySchoolOfficesArgs = {
  input: SchoolOfficesInput;
};


export type QuerySchoolPrincipalArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolPrincipalAssistantsArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolSemestersArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolSpEdAdminArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolSpEdAdminAssistantsArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolSpEdCaseManagersArgs = {
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolTeachersArgs = {
  filter?: InputMaybe<Filter>;
  schoolId: Scalars['ObjectId'];
};


export type QuerySchoolsArgs = {
  filters?: InputMaybe<Filter>;
};


export type QuerySectionArgs = {
  sectionId: Scalars['ObjectId'];
};


export type QuerySectionHiddenStudentsArgs = {
  teacherId: Scalars['ObjectId'];
};


export type QuerySectionReportsArgs = {
  input: SectionsReportsQueryInput;
};


export type QuerySectionsByPeriodArgs = {
  period: Scalars['String'];
};


export type QuerySemesterArgs = {
  semesterId: Scalars['ObjectId'];
};


export type QueryStudentArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryStudentBathroomUsageArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryStudentSectionAttendanceReportsArgs = {
  input: StudentSectionReportsQueryInput;
};


export type QueryStudentTeachersArgs = {
  studentId: Scalars['ObjectId'];
};


export type QueryStudentsArgs = {
  filter?: InputMaybe<Filter>;
  searchInput: StudentsSearchInput;
};


export type QueryTeacherAbsentReportsArgs = {
  input: TeacherAbsentReportsQueryInput;
};


export type QueryTeacherSectionsArgs = {
  teacherId: Scalars['ObjectId'];
};


export type QueryUploadReportsArgs = {
  searchInput?: InputMaybe<UploadReportsSearchInput>;
};


export type QueryUserArgs = {
  _id: Scalars['ID'];
};


export type QueryUserInvitationArgs = {
  invitationId: Scalars['ObjectId'];
};


export type QueryUsersArgs = {
  args: UsersQueryArgs;
};

export type RecentHallpassesInput = {
  districtId: Scalars['ObjectId'];
  officeId?: InputMaybe<Scalars['ObjectId']>;
  studentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  type?: InputMaybe<HallpassType>;
};

export type RegisterUserInput = {
  districtId: Scalars['ObjectId'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationId: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  schoolId: Scalars['ObjectId'];
};

export type ReportStats = {
  amounts: Array<Scalars['Int']>;
  percentages: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type ReportStatsPerType = {
  amounts: Array<Scalars['Int']>;
  author: User;
  authorId: Scalars['ObjectId'];
  percentages: Array<Scalars['Float']>;
  section: Section;
  sectionId: Scalars['ObjectId'];
};

export type RequestMentorRoleNotification = {
  _id: Scalars['ObjectId'];
  by: Scalars['ObjectId'];
  content: Scalars['String'];
  isRead: Scalars['Boolean'];
  studentId: Scalars['ObjectId'];
  title: Scalars['String'];
  to: Scalars['ObjectId'];
};

export type Role = {
  _id: Scalars['ObjectId'];
  ownerId: Scalars['ObjectId'];
  resourceIds: Array<Scalars['ObjectId']>;
  title: RoleTitle;
};

export enum RoleTitle {
  DistrictAdmin = 'DistrictAdmin',
  DistrictAdminAssistant = 'DistrictAdminAssistant',
  DistrictAthelticDirectorAssistant = 'DistrictAthelticDirectorAssistant',
  DistrictAthleticDirector = 'DistrictAthleticDirector',
  DistrictCoordinator = 'DistrictCoordinator',
  DistrictHandler = 'DistrictHandler',
  DistrictSpEdAdmin = 'DistrictSpEdAdmin',
  DistrictSpEdAdminAssistant = 'DistrictSpEdAdminAssistant',
  DistrictSpecialist = 'DistrictSpecialist',
  Hb4545Teacher = 'HB4545Teacher',
  SchoolCounselor = 'SchoolCounselor',
  SchoolMentor = 'SchoolMentor',
  SchoolPrincipal = 'SchoolPrincipal',
  SchoolPrincipalAssistant = 'SchoolPrincipalAssistant',
  SchoolSpEdAdmin = 'SchoolSpEdAdmin',
  SchoolSpEdAdminAssistant = 'SchoolSpEdAdminAssistant',
  SchoolSpEdCaseManager = 'SchoolSpEdCaseManager',
  SectionTeacher = 'SectionTeacher',
  StudentMentor = 'StudentMentor',
  StudentTeacher = 'StudentTeacher'
}

/** Union result of save bathroom limit mutation. */
export type SaveBathroomLimitResult = SaveBathroomLimitSuccess;

export type SaveBathroomLimitSuccess = {
  bathroomLimit?: Maybe<Scalars['Int']>;
};

/** Union result of save restricted students mutation. */
export type SaveBathroomRestrictedStudentsResult = BathroomRestrictedStudentsOutOfLimitError | NotFoundError | SaveBathroomRestrictedStudentsSuccess;

export type SaveBathroomRestrictedStudentsSuccess = {
  bathroomRestrictedStudentIds: Array<Scalars['ObjectId']>;
};

export type School = {
  _id: Scalars['String'];
  caseManagers: Array<User>;
  cleverId?: Maybe<Scalars['String']>;
  counselors: Array<User>;
  district: District;
  isAskEdEnabled: Scalars['Boolean'];
  name: Scalars['String'];
  periods: Array<SchoolPeriod>;
  principal?: Maybe<User>;
  principalAssistants: Array<User>;
  spEdAdmin?: Maybe<User>;
  spEdAdminAssistants: Array<User>;
  staffs: Array<User>;
  students: Array<Student>;
  studentsAmount: StudentsAmount;
  studentsFilters: StudentsFilters;
};

export type SchoolEvent = {
  _id: Scalars['ObjectId'];
  date: Scalars['Float'];
  type: SchoolEventType;
};

export enum SchoolEventType {
  A = 'A',
  B = 'B',
  C = 'C'
}

export type SchoolOffice = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  createdDate: Scalars['Float'];
  isArchived: Scalars['Boolean'];
  school: School;
  schoolId: Scalars['ObjectId'];
  staffIds: Array<Scalars['ObjectId']>;
  staffs: User;
  title: Scalars['String'];
};

export type SchoolOfficeUsedTitleError = {
  message: Scalars['String'];
  title: Scalars['String'];
};

export type SchoolOfficesInput = {
  schoolId?: InputMaybe<Scalars['ObjectId']>;
  staffId?: InputMaybe<Scalars['ObjectId']>;
};

export type SchoolPeriod = {
  end: Scalars['String'];
  event: SchoolEventType;
  period: Scalars['String'];
  start: Scalars['String'];
};

export type SchoolPeriodsInput = {
  end: Scalars['String'];
  event: SchoolEventType;
  period: Scalars['String'];
  start: Scalars['String'];
};

export type Section = {
  _id: Scalars['ObjectId'];
  allowedPeriodsForHB4545?: Maybe<Array<Scalars['String']>>;
  author?: Maybe<User>;
  cleverId?: Maybe<Scalars['ID']>;
  days?: Maybe<Array<Scalars['String']>>;
  districtId: Scalars['ObjectId'];
  name: Scalars['String'];
  period: Scalars['String'];
  periodDisplayName?: Maybe<Scalars['String']>;
  roomNumber?: Maybe<Scalars['String']>;
  schoolId: Scalars['ObjectId'];
  startTime?: Maybe<Scalars['Float']>;
  students: Array<Student>;
  subject: Scalars['String'];
  teachers: Array<User>;
};

export type SectionHiddenStudent = {
  _id: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  teacherId: Scalars['ObjectId'];
};

export type SectionReport = AccommReport | AttendanceReport | BehaviorReport | GoalReport | Hallpass | TeacherAbsentReport | UploadReport;

export type SectionsReportsQueryInput = {
  fromDate: Scalars['Float'];
  sectionsIds: Array<Scalars['ObjectId']>;
  toDate: Scalars['Float'];
};

export type Semester = {
  _id: Scalars['ObjectId'];
  districtId: Scalars['ObjectId'];
  name: Scalars['String'];
  schoolId: Scalars['ObjectId'];
  sectionIds?: Maybe<Array<Scalars['ObjectId']>>;
};

export type SetGradingPeriodsInput = {
  end: Scalars['Float'];
  pr: Scalars['Float'];
  semesterId?: InputMaybe<Scalars['ObjectId']>;
  start: Scalars['Float'];
};

export type StopInProgressHallpassInput = {
  endDate: Scalars['Float'];
  hallpassId: Scalars['ObjectId'];
};

export type Student = {
  _id: Scalars['ObjectId'];
  accomms?: Maybe<Array<Accomm>>;
  age?: Maybe<Scalars['Float']>;
  /** Behavior reports texts from all behavior reports */
  allReceivedBehaviorTexts?: Maybe<Array<Scalars['String']>>;
  archived: Scalars['Boolean'];
  attendanceReports: Array<AttendanceReport>;
  bathroomAmounts?: Maybe<Scalars['Int']>;
  bathroomUsage: StudentBathroomUsage;
  cleverId?: Maybe<Scalars['String']>;
  comment?: Maybe<StudentComment>;
  /** lastName and firstName seperated by comma */
  displayName: Scalars['String'];
  district: District;
  districtId: Scalars['ObjectId'];
  email?: Maybe<Scalars['String']>;
  failedEOCs?: Maybe<Array<EocSubject>>;
  firstName: Scalars['String'];
  goals?: Maybe<Array<Goal>>;
  hasSectionReportOnDate: Scalars['Boolean'];
  hb4545Reports?: Maybe<Array<Hb4545StudentReport>>;
  isAbsentOnDate: Scalars['Boolean'];
  isELL?: Maybe<Scalars['Boolean']>;
  isGEN?: Maybe<Scalars['Boolean']>;
  isIEP?: Maybe<Scalars['Boolean']>;
  isPlan504?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  mentors?: Maybe<Array<User>>;
  middleName?: Maybe<Scalars['String']>;
  /** Returns most recent 5 reports */
  mostRecentBehaviorReports?: Maybe<Array<BehaviorReport>>;
  parallelEvent?: Maybe<Event>;
  parallelHB4545Section?: Maybe<Section>;
  reportsStats?: Maybe<Array<ReportStats>>;
  rti?: Maybe<Scalars['Float']>;
  school: School;
  schoolId: Scalars['ObjectId'];
  studentTeachers: Array<User>;
};


export type StudentIsAbsentOnDateArgs = {
  endOfDate: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
  startOfDate: Scalars['Float'];
};


export type StudentParallelEventArgs = {
  date: Scalars['Float'];
  period: Scalars['String'];
};


export type StudentParallelHb4545SectionArgs = {
  dayOfTheWeek: Scalars['String'];
  period: Scalars['String'];
};

export type StudentBathroomUsage = {
  day: Scalars['Int'];
  gradingPeriod: Scalars['Int'];
  month: Scalars['Int'];
  week: Scalars['Int'];
};

export type StudentComment = {
  author: User;
  date: Scalars['Float'];
  text: Scalars['String'];
};

export type StudentSectionReportsQueryInput = {
  fromDate: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  toDate: Scalars['Float'];
};

export type StudentsAmount = {
  all: Scalars['Int'];
  ell: Scalars['Int'];
  iep: Scalars['Int'];
  plan504: Scalars['Int'];
};

export type StudentsFilters = {
  ell?: Maybe<Scalars['Int']>;
  iep?: Maybe<Scalars['Int']>;
  plan504?: Maybe<Scalars['Int']>;
};

export type StudentsFiltersInput = {
  ell?: InputMaybe<Scalars['Int']>;
  iep?: InputMaybe<Scalars['Int']>;
  plan504?: InputMaybe<Scalars['Int']>;
};

export type StudentsSearchInput = {
  districtId?: InputMaybe<Scalars['ObjectId']>;
  excludeStudents?: InputMaybe<Array<Scalars['ObjectId']>>;
  includeStudents?: InputMaybe<Array<Scalars['ObjectId']>>;
  isELL?: InputMaybe<Scalars['Boolean']>;
  isGEN?: InputMaybe<Scalars['Boolean']>;
  isIEP?: InputMaybe<Scalars['Boolean']>;
  isPlan504?: InputMaybe<Scalars['Boolean']>;
  mentorId?: InputMaybe<Scalars['ObjectId']>;
  name?: InputMaybe<Scalars['String']>;
  rti?: InputMaybe<Scalars['Int']>;
  schoolId?: InputMaybe<Scalars['ObjectId']>;
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  teacherId?: InputMaybe<Scalars['ObjectId']>;
};

export type TeacherAbsentReport = {
  _id: Scalars['ObjectId'];
  author: User;
  date: Scalars['Float'];
  section: Section;
  teacher: User;
};

export type TeacherAbsentReportsQueryInput = {
  authorId: Scalars['ObjectId'];
  fromDate: Scalars['Float'];
  sectionId: Scalars['ObjectId'];
  teacherId: Scalars['ObjectId'];
  toDate: Scalars['Float'];
};

export type UnauthorizedStudentInHallwayNotification = {
  _id: Scalars['ObjectId'];
  content: Scalars['String'];
  isRead: Scalars['Boolean'];
  title: Scalars['String'];
};

export type UpdateAccommReportInput = {
  _id: Scalars['ObjectId'];
  updateFields: AccommReportUpdateFieldsInput;
};

export type UpdateBehaviorReportInput = {
  _id: Scalars['ObjectId'];
  updateFields: BehaviorReportUpdateFields;
};

export type UpdateDailyPostInput = {
  _id: Scalars['ObjectId'];
  body?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['Float']>;
  publishedDate?: InputMaybe<Scalars['Float']>;
  subject?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DailyPostType>;
};

export type UpdateEventInput = {
  _id: Scalars['ObjectId'];
  allowedPeriods: Array<Scalars['String']>;
  authorId: Scalars['ObjectId'];
  dates: Array<Scalars['Float']>;
  description: Scalars['String'];
  dismissalTime: Scalars['Float'];
  eventStudentsGroupIds: Array<Scalars['ObjectId']>;
  invitedStaffIds: Array<Scalars['ObjectId']>;
  location: Scalars['String'];
  name: Scalars['String'];
  studentIds: Array<Scalars['ObjectId']>;
  studentsInTreeSelect: Array<Scalars['String']>;
  type: EventType;
};

export type UpdateGoalReportInput = {
  _id: Scalars['ObjectId'];
  updateFields: GoalReportUpdateFieldsInput;
};

export type UpdateHb4545SectionInput = {
  allowedPeriodsForHB4545?: InputMaybe<Array<Scalars['String']>>;
  days?: InputMaybe<Array<Scalars['String']>>;
  hb4545SectionId: Scalars['ObjectId'];
  name?: InputMaybe<Scalars['String']>;
  roomNumber?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Float'];
  subject?: InputMaybe<Scalars['String']>;
  teacherIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateHb4545Session = Hb4545Session | Hb4545SessionNotFoundError;

export type UpdateHb4545SessionInput = {
  absentStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  attendedStudentIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  comment?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Float']>;
  durationInMilliseconds?: InputMaybe<Scalars['Float']>;
};

export type UpdateSectionStudentVisibilityInput = {
  isStudentHidden: Scalars['Boolean'];
  studentId: Scalars['ObjectId'];
  teacherId: Scalars['ObjectId'];
};

export type UpdateSectionsInput = {
  periodDisplayName?: InputMaybe<Scalars['String']>;
  roomNumber?: InputMaybe<Scalars['String']>;
  sectionsIds: Array<Scalars['ObjectId']>;
};

export type UpdateSemesterInput = {
  districtId?: InputMaybe<Scalars['ObjectId']>;
  name?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['ObjectId']>;
  sectionIds?: InputMaybe<Array<Scalars['ObjectId']>>;
};

export type UpdateStudentTypeInput = {
  isELL?: InputMaybe<Scalars['Boolean']>;
  isIEP?: InputMaybe<Scalars['Boolean']>;
  isPlan504?: InputMaybe<Scalars['Boolean']>;
  rti?: InputMaybe<Scalars['Float']>;
  studentId: Scalars['ObjectId'];
};

export type UploadReport = {
  _id: Scalars['ObjectId'];
  author: User;
  authorId: Scalars['ObjectId'];
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Float'];
  files: Array<File>;
  section: Section;
  sectionId: Scalars['ObjectId'];
  student: Student;
  studentId: Scalars['ObjectId'];
};

export type UploadReportsSearchInput = {
  authorId?: InputMaybe<Scalars['ObjectId']>;
  fromDate: Scalars['Float'];
  sectionId?: InputMaybe<Scalars['ObjectId']>;
  studentId?: InputMaybe<Scalars['ObjectId']>;
  toDate: Scalars['Float'];
};

export type UpsertAccommInput = {
  _id: Scalars['ObjectId'];
  category: Scalars['String'];
  deleted?: InputMaybe<Scalars['Boolean']>;
  status: Scalars['String'];
  studentId: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type UpsertEventAttendanceReportInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  date: Scalars['Float'];
  eventId: Scalars['ObjectId'];
  studentId: Scalars['ObjectId'];
  type: Scalars['String'];
};

export type UpsertEventStudentsGroupInput = {
  _id: Scalars['ObjectId'];
  authorId: Scalars['ObjectId'];
  name: Scalars['String'];
  studentIds: Array<Scalars['ObjectId']>;
};

export type UpsertGoalsInput = {
  _id: Scalars['ObjectId'];
  calcType: GoalCalcType;
  category: Scalars['String'];
  deleted?: InputMaybe<Scalars['Boolean']>;
  studentId: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type UpsertLessonPlanInput = {
  date: Scalars['Float'];
  description: Scalars['String'];
  sectionsIds: Array<Scalars['ObjectId']>;
};

export type User = {
  _id: Scalars['ObjectId'];
  cleverId?: Maybe<Scalars['ID']>;
  /** lastName and firstName seperated by comma */
  displayName: Scalars['String'];
  districtId: Scalars['ObjectId'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  myHB4545Sections?: Maybe<Array<Section>>;
  mySections?: Maybe<Array<Section>>;
  password?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  schoolId?: Maybe<Scalars['ObjectId']>;
  shouldAskNewPassword: Scalars['Boolean'];
  userAssignedOffices?: Maybe<Array<SchoolOffice>>;
};

export type UserFeedbackInput = {
  feedback: Scalars['String'];
  type: Scalars['String'];
};

export type UserInvitation = {
  district: District;
  email: Scalars['String'];
  school?: Maybe<School>;
};

export type UsersQueryArgs = {
  districtId: Scalars['ObjectId'];
  excludeStaffIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  filter?: InputMaybe<Filter>;
  includeStaffIds?: InputMaybe<Array<Scalars['ObjectId']>>;
  schoolId: Scalars['ObjectId'];
};

export type AddProductUpdateMutationVariables = Exact<{
  input: AddProductUpdateInput;
}>;


export type AddProductUpdateMutation = { addProductUpdate: { _id: any } };

export type LoggedAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedAdminQuery = { loggedAdmin?: { _id: any, username: string } | null };

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;


export type InviteUserMutation = { inviteUser: boolean };

export type SchoolsQueryVariables = Exact<{
  districtId: Scalars['ObjectId'];
  filter?: InputMaybe<Filter>;
}>;


export type SchoolsQuery = { districtSchools: Array<{ _id: string, name: string }> };

export type AddDistrictHandlerMutationVariables = Exact<{
  districtId: Scalars['ObjectId'];
  userEmail: Scalars['String'];
}>;


export type AddDistrictHandlerMutation = { addDistrictHandler: boolean };

export type RemoveDistrictHandlerMutationVariables = Exact<{
  districtId: Scalars['ObjectId'];
  districtHandlerId: Scalars['ObjectId'];
}>;


export type RemoveDistrictHandlerMutation = { removeDistrictHandler: boolean };

export type DistrictHandlerQueryVariables = Exact<{
  districtId: Scalars['ObjectId'];
}>;


export type DistrictHandlerQuery = { districtHandler?: { _id: any, firstName: string, lastName: string, email: string } | null };

export type AllConnectedDistirctsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllConnectedDistirctsQuery = { allConnectedDistricts: Array<{ _id: string, cleverId?: string | null, classlinkId?: string | null, name: string, syncStatus?: DistrictSyncStatus | null, lastSynced?: number | null }> };

export type StartDistrictSyncMutationVariables = Exact<{
  district: DistrictSyncInput;
}>;


export type StartDistrictSyncMutation = { startDistrictSync: boolean };

export type CreateNewSemesterMutationVariables = Exact<{
  input: CreateSemesterInput;
}>;


export type CreateNewSemesterMutation = { createSemester: boolean };

export type DeleteSemesterMutationVariables = Exact<{
  semesterId: Scalars['ObjectId'];
}>;


export type DeleteSemesterMutation = { deleteSemester: boolean };

export type EnableAskEdToSchoolMutationVariables = Exact<{
  schoolId: Scalars['ObjectId'];
}>;


export type EnableAskEdToSchoolMutation = { enableAskEdToSchool: boolean };

export type SchoolSemestersQueryVariables = Exact<{
  schoolId: Scalars['ObjectId'];
}>;


export type SchoolSemestersQuery = { schoolSemesters: Array<{ _id: any, name: string }> };

export type SchoolsManageSchoolsListingQueryVariables = Exact<{
  districtId: Scalars['ObjectId'];
  filter?: InputMaybe<Filter>;
}>;


export type SchoolsManageSchoolsListingQuery = { districtSchools: Array<{ _id: string, name: string, isAskEdEnabled: boolean, studentsFilters: { iep?: number | null, ell?: number | null, plan504?: number | null } }> };

export type SetStudentsFiltersMutationVariables = Exact<{
  schoolId: Scalars['ObjectId'];
  studentsFilters: StudentsFiltersInput;
}>;


export type SetStudentsFiltersMutation = { setStudentsFilters: boolean };

export type UpdateSemesterMutationVariables = Exact<{
  semesterId: Scalars['ObjectId'];
  input: UpdateSemesterInput;
}>;


export type UpdateSemesterMutation = { updateSemester: boolean };

export type LoginAdminMutationVariables = Exact<{
  input: AdminLoginInput;
}>;


export type LoginAdminMutation = { token: string };


export const AddProductUpdateDocument = gql`
    mutation AddProductUpdate($input: AddProductUpdateInput!) {
  addProductUpdate(input: $input) {
    _id
  }
}
    `;
export type AddProductUpdateMutationFn = Apollo.MutationFunction<AddProductUpdateMutation, AddProductUpdateMutationVariables>;

/**
 * __useAddProductUpdateMutation__
 *
 * To run a mutation, you first call `useAddProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductUpdateMutation, { data, loading, error }] = useAddProductUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProductUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AddProductUpdateMutation, AddProductUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProductUpdateMutation, AddProductUpdateMutationVariables>(AddProductUpdateDocument, options);
      }
export type AddProductUpdateMutationHookResult = ReturnType<typeof useAddProductUpdateMutation>;
export type AddProductUpdateMutationResult = Apollo.MutationResult<AddProductUpdateMutation>;
export type AddProductUpdateMutationOptions = Apollo.BaseMutationOptions<AddProductUpdateMutation, AddProductUpdateMutationVariables>;
export const LoggedAdminDocument = gql`
    query LoggedAdmin {
  loggedAdmin {
    _id
    username
  }
}
    `;

/**
 * __useLoggedAdminQuery__
 *
 * To run a query within a React component, call `useLoggedAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedAdminQuery(baseOptions?: Apollo.QueryHookOptions<LoggedAdminQuery, LoggedAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedAdminQuery, LoggedAdminQueryVariables>(LoggedAdminDocument, options);
      }
export function useLoggedAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedAdminQuery, LoggedAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedAdminQuery, LoggedAdminQueryVariables>(LoggedAdminDocument, options);
        }
export type LoggedAdminQueryHookResult = ReturnType<typeof useLoggedAdminQuery>;
export type LoggedAdminLazyQueryHookResult = ReturnType<typeof useLoggedAdminLazyQuery>;
export type LoggedAdminQueryResult = Apollo.QueryResult<LoggedAdminQuery, LoggedAdminQueryVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: InviteUserInput!) {
  inviteUser(input: $input)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const SchoolsDocument = gql`
    query Schools($districtId: ObjectId!, $filter: Filter) {
  districtSchools(districtId: $districtId, filter: $filter) {
    _id
    name
  }
}
    `;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
      }
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
        }
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const AddDistrictHandlerDocument = gql`
    mutation AddDistrictHandler($districtId: ObjectId!, $userEmail: String!) {
  addDistrictHandler(districtId: $districtId, userEmail: $userEmail)
}
    `;
export type AddDistrictHandlerMutationFn = Apollo.MutationFunction<AddDistrictHandlerMutation, AddDistrictHandlerMutationVariables>;

/**
 * __useAddDistrictHandlerMutation__
 *
 * To run a mutation, you first call `useAddDistrictHandlerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDistrictHandlerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDistrictHandlerMutation, { data, loading, error }] = useAddDistrictHandlerMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useAddDistrictHandlerMutation(baseOptions?: Apollo.MutationHookOptions<AddDistrictHandlerMutation, AddDistrictHandlerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDistrictHandlerMutation, AddDistrictHandlerMutationVariables>(AddDistrictHandlerDocument, options);
      }
export type AddDistrictHandlerMutationHookResult = ReturnType<typeof useAddDistrictHandlerMutation>;
export type AddDistrictHandlerMutationResult = Apollo.MutationResult<AddDistrictHandlerMutation>;
export type AddDistrictHandlerMutationOptions = Apollo.BaseMutationOptions<AddDistrictHandlerMutation, AddDistrictHandlerMutationVariables>;
export const RemoveDistrictHandlerDocument = gql`
    mutation RemoveDistrictHandler($districtId: ObjectId!, $districtHandlerId: ObjectId!) {
  removeDistrictHandler(
    districtId: $districtId
    districtHandlerId: $districtHandlerId
  )
}
    `;
export type RemoveDistrictHandlerMutationFn = Apollo.MutationFunction<RemoveDistrictHandlerMutation, RemoveDistrictHandlerMutationVariables>;

/**
 * __useRemoveDistrictHandlerMutation__
 *
 * To run a mutation, you first call `useRemoveDistrictHandlerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDistrictHandlerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDistrictHandlerMutation, { data, loading, error }] = useRemoveDistrictHandlerMutation({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      districtHandlerId: // value for 'districtHandlerId'
 *   },
 * });
 */
export function useRemoveDistrictHandlerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDistrictHandlerMutation, RemoveDistrictHandlerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDistrictHandlerMutation, RemoveDistrictHandlerMutationVariables>(RemoveDistrictHandlerDocument, options);
      }
export type RemoveDistrictHandlerMutationHookResult = ReturnType<typeof useRemoveDistrictHandlerMutation>;
export type RemoveDistrictHandlerMutationResult = Apollo.MutationResult<RemoveDistrictHandlerMutation>;
export type RemoveDistrictHandlerMutationOptions = Apollo.BaseMutationOptions<RemoveDistrictHandlerMutation, RemoveDistrictHandlerMutationVariables>;
export const DistrictHandlerDocument = gql`
    query DistrictHandler($districtId: ObjectId!) {
  districtHandler(districtId: $districtId) {
    _id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useDistrictHandlerQuery__
 *
 * To run a query within a React component, call `useDistrictHandlerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictHandlerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictHandlerQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *   },
 * });
 */
export function useDistrictHandlerQuery(baseOptions: Apollo.QueryHookOptions<DistrictHandlerQuery, DistrictHandlerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistrictHandlerQuery, DistrictHandlerQueryVariables>(DistrictHandlerDocument, options);
      }
export function useDistrictHandlerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistrictHandlerQuery, DistrictHandlerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistrictHandlerQuery, DistrictHandlerQueryVariables>(DistrictHandlerDocument, options);
        }
export type DistrictHandlerQueryHookResult = ReturnType<typeof useDistrictHandlerQuery>;
export type DistrictHandlerLazyQueryHookResult = ReturnType<typeof useDistrictHandlerLazyQuery>;
export type DistrictHandlerQueryResult = Apollo.QueryResult<DistrictHandlerQuery, DistrictHandlerQueryVariables>;
export const AllConnectedDistirctsDocument = gql`
    query AllConnectedDistircts {
  allConnectedDistricts {
    _id
    cleverId
    classlinkId
    name
    syncStatus
    lastSynced
  }
}
    `;

/**
 * __useAllConnectedDistirctsQuery__
 *
 * To run a query within a React component, call `useAllConnectedDistirctsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllConnectedDistirctsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllConnectedDistirctsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllConnectedDistirctsQuery(baseOptions?: Apollo.QueryHookOptions<AllConnectedDistirctsQuery, AllConnectedDistirctsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllConnectedDistirctsQuery, AllConnectedDistirctsQueryVariables>(AllConnectedDistirctsDocument, options);
      }
export function useAllConnectedDistirctsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllConnectedDistirctsQuery, AllConnectedDistirctsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllConnectedDistirctsQuery, AllConnectedDistirctsQueryVariables>(AllConnectedDistirctsDocument, options);
        }
export type AllConnectedDistirctsQueryHookResult = ReturnType<typeof useAllConnectedDistirctsQuery>;
export type AllConnectedDistirctsLazyQueryHookResult = ReturnType<typeof useAllConnectedDistirctsLazyQuery>;
export type AllConnectedDistirctsQueryResult = Apollo.QueryResult<AllConnectedDistirctsQuery, AllConnectedDistirctsQueryVariables>;
export const StartDistrictSyncDocument = gql`
    mutation startDistrictSync($district: DistrictSyncInput!) {
  startDistrictSync(district: $district)
}
    `;
export type StartDistrictSyncMutationFn = Apollo.MutationFunction<StartDistrictSyncMutation, StartDistrictSyncMutationVariables>;

/**
 * __useStartDistrictSyncMutation__
 *
 * To run a mutation, you first call `useStartDistrictSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDistrictSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDistrictSyncMutation, { data, loading, error }] = useStartDistrictSyncMutation({
 *   variables: {
 *      district: // value for 'district'
 *   },
 * });
 */
export function useStartDistrictSyncMutation(baseOptions?: Apollo.MutationHookOptions<StartDistrictSyncMutation, StartDistrictSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartDistrictSyncMutation, StartDistrictSyncMutationVariables>(StartDistrictSyncDocument, options);
      }
export type StartDistrictSyncMutationHookResult = ReturnType<typeof useStartDistrictSyncMutation>;
export type StartDistrictSyncMutationResult = Apollo.MutationResult<StartDistrictSyncMutation>;
export type StartDistrictSyncMutationOptions = Apollo.BaseMutationOptions<StartDistrictSyncMutation, StartDistrictSyncMutationVariables>;
export const CreateNewSemesterDocument = gql`
    mutation CreateNewSemester($input: CreateSemesterInput!) {
  createSemester(input: $input)
}
    `;
export type CreateNewSemesterMutationFn = Apollo.MutationFunction<CreateNewSemesterMutation, CreateNewSemesterMutationVariables>;

/**
 * __useCreateNewSemesterMutation__
 *
 * To run a mutation, you first call `useCreateNewSemesterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSemesterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewSemesterMutation, { data, loading, error }] = useCreateNewSemesterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewSemesterMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewSemesterMutation, CreateNewSemesterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewSemesterMutation, CreateNewSemesterMutationVariables>(CreateNewSemesterDocument, options);
      }
export type CreateNewSemesterMutationHookResult = ReturnType<typeof useCreateNewSemesterMutation>;
export type CreateNewSemesterMutationResult = Apollo.MutationResult<CreateNewSemesterMutation>;
export type CreateNewSemesterMutationOptions = Apollo.BaseMutationOptions<CreateNewSemesterMutation, CreateNewSemesterMutationVariables>;
export const DeleteSemesterDocument = gql`
    mutation DeleteSemester($semesterId: ObjectId!) {
  deleteSemester(semesterId: $semesterId)
}
    `;
export type DeleteSemesterMutationFn = Apollo.MutationFunction<DeleteSemesterMutation, DeleteSemesterMutationVariables>;

/**
 * __useDeleteSemesterMutation__
 *
 * To run a mutation, you first call `useDeleteSemesterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSemesterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSemesterMutation, { data, loading, error }] = useDeleteSemesterMutation({
 *   variables: {
 *      semesterId: // value for 'semesterId'
 *   },
 * });
 */
export function useDeleteSemesterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSemesterMutation, DeleteSemesterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSemesterMutation, DeleteSemesterMutationVariables>(DeleteSemesterDocument, options);
      }
export type DeleteSemesterMutationHookResult = ReturnType<typeof useDeleteSemesterMutation>;
export type DeleteSemesterMutationResult = Apollo.MutationResult<DeleteSemesterMutation>;
export type DeleteSemesterMutationOptions = Apollo.BaseMutationOptions<DeleteSemesterMutation, DeleteSemesterMutationVariables>;
export const EnableAskEdToSchoolDocument = gql`
    mutation EnableAskEdToSchool($schoolId: ObjectId!) {
  enableAskEdToSchool(schoolId: $schoolId)
}
    `;
export type EnableAskEdToSchoolMutationFn = Apollo.MutationFunction<EnableAskEdToSchoolMutation, EnableAskEdToSchoolMutationVariables>;

/**
 * __useEnableAskEdToSchoolMutation__
 *
 * To run a mutation, you first call `useEnableAskEdToSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableAskEdToSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableAskEdToSchoolMutation, { data, loading, error }] = useEnableAskEdToSchoolMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useEnableAskEdToSchoolMutation(baseOptions?: Apollo.MutationHookOptions<EnableAskEdToSchoolMutation, EnableAskEdToSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableAskEdToSchoolMutation, EnableAskEdToSchoolMutationVariables>(EnableAskEdToSchoolDocument, options);
      }
export type EnableAskEdToSchoolMutationHookResult = ReturnType<typeof useEnableAskEdToSchoolMutation>;
export type EnableAskEdToSchoolMutationResult = Apollo.MutationResult<EnableAskEdToSchoolMutation>;
export type EnableAskEdToSchoolMutationOptions = Apollo.BaseMutationOptions<EnableAskEdToSchoolMutation, EnableAskEdToSchoolMutationVariables>;
export const SchoolSemestersDocument = gql`
    query SchoolSemesters($schoolId: ObjectId!) {
  schoolSemesters(schoolId: $schoolId) {
    _id
    name
  }
}
    `;

/**
 * __useSchoolSemestersQuery__
 *
 * To run a query within a React component, call `useSchoolSemestersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolSemestersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolSemestersQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useSchoolSemestersQuery(baseOptions: Apollo.QueryHookOptions<SchoolSemestersQuery, SchoolSemestersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolSemestersQuery, SchoolSemestersQueryVariables>(SchoolSemestersDocument, options);
      }
export function useSchoolSemestersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolSemestersQuery, SchoolSemestersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolSemestersQuery, SchoolSemestersQueryVariables>(SchoolSemestersDocument, options);
        }
export type SchoolSemestersQueryHookResult = ReturnType<typeof useSchoolSemestersQuery>;
export type SchoolSemestersLazyQueryHookResult = ReturnType<typeof useSchoolSemestersLazyQuery>;
export type SchoolSemestersQueryResult = Apollo.QueryResult<SchoolSemestersQuery, SchoolSemestersQueryVariables>;
export const SchoolsManageSchoolsListingDocument = gql`
    query SchoolsManageSchoolsListing($districtId: ObjectId!, $filter: Filter) {
  districtSchools(districtId: $districtId, filter: $filter) {
    _id
    name
    studentsFilters {
      iep
      ell
      plan504
    }
    isAskEdEnabled
  }
}
    `;

/**
 * __useSchoolsManageSchoolsListingQuery__
 *
 * To run a query within a React component, call `useSchoolsManageSchoolsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsManageSchoolsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsManageSchoolsListingQuery({
 *   variables: {
 *      districtId: // value for 'districtId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSchoolsManageSchoolsListingQuery(baseOptions: Apollo.QueryHookOptions<SchoolsManageSchoolsListingQuery, SchoolsManageSchoolsListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsManageSchoolsListingQuery, SchoolsManageSchoolsListingQueryVariables>(SchoolsManageSchoolsListingDocument, options);
      }
export function useSchoolsManageSchoolsListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsManageSchoolsListingQuery, SchoolsManageSchoolsListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsManageSchoolsListingQuery, SchoolsManageSchoolsListingQueryVariables>(SchoolsManageSchoolsListingDocument, options);
        }
export type SchoolsManageSchoolsListingQueryHookResult = ReturnType<typeof useSchoolsManageSchoolsListingQuery>;
export type SchoolsManageSchoolsListingLazyQueryHookResult = ReturnType<typeof useSchoolsManageSchoolsListingLazyQuery>;
export type SchoolsManageSchoolsListingQueryResult = Apollo.QueryResult<SchoolsManageSchoolsListingQuery, SchoolsManageSchoolsListingQueryVariables>;
export const SetStudentsFiltersDocument = gql`
    mutation SetStudentsFilters($schoolId: ObjectId!, $studentsFilters: StudentsFiltersInput!) {
  setStudentsFilters(schoolId: $schoolId, studentsFilters: $studentsFilters)
}
    `;
export type SetStudentsFiltersMutationFn = Apollo.MutationFunction<SetStudentsFiltersMutation, SetStudentsFiltersMutationVariables>;

/**
 * __useSetStudentsFiltersMutation__
 *
 * To run a mutation, you first call `useSetStudentsFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStudentsFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStudentsFiltersMutation, { data, loading, error }] = useSetStudentsFiltersMutation({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      studentsFilters: // value for 'studentsFilters'
 *   },
 * });
 */
export function useSetStudentsFiltersMutation(baseOptions?: Apollo.MutationHookOptions<SetStudentsFiltersMutation, SetStudentsFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStudentsFiltersMutation, SetStudentsFiltersMutationVariables>(SetStudentsFiltersDocument, options);
      }
export type SetStudentsFiltersMutationHookResult = ReturnType<typeof useSetStudentsFiltersMutation>;
export type SetStudentsFiltersMutationResult = Apollo.MutationResult<SetStudentsFiltersMutation>;
export type SetStudentsFiltersMutationOptions = Apollo.BaseMutationOptions<SetStudentsFiltersMutation, SetStudentsFiltersMutationVariables>;
export const UpdateSemesterDocument = gql`
    mutation UpdateSemester($semesterId: ObjectId!, $input: UpdateSemesterInput!) {
  updateSemester(semesterId: $semesterId, input: $input)
}
    `;
export type UpdateSemesterMutationFn = Apollo.MutationFunction<UpdateSemesterMutation, UpdateSemesterMutationVariables>;

/**
 * __useUpdateSemesterMutation__
 *
 * To run a mutation, you first call `useUpdateSemesterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSemesterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSemesterMutation, { data, loading, error }] = useUpdateSemesterMutation({
 *   variables: {
 *      semesterId: // value for 'semesterId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSemesterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSemesterMutation, UpdateSemesterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSemesterMutation, UpdateSemesterMutationVariables>(UpdateSemesterDocument, options);
      }
export type UpdateSemesterMutationHookResult = ReturnType<typeof useUpdateSemesterMutation>;
export type UpdateSemesterMutationResult = Apollo.MutationResult<UpdateSemesterMutation>;
export type UpdateSemesterMutationOptions = Apollo.BaseMutationOptions<UpdateSemesterMutation, UpdateSemesterMutationVariables>;
export const LoginAdminDocument = gql`
    mutation LoginAdmin($input: AdminLoginInput!) {
  token: loginAdmin(input: $input)
}
    `;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;