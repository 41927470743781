import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Layout, Menu } from "antd";
import { LoginOutlined, NotificationOutlined } from "@ant-design/icons";
import Logo from "design/logo.svg";

import { useAdminContex } from "context";
import { paths } from "utils/paths";

export function Header() {
  const { logout } = useAdminContex();
  const history = useHistory();

  return (
    <StyledHeader>
      <Link to="/" className="logo">
        <img src={Logo} alt="" />
      </Link>
      <div>
        <Menu theme="light" mode="horizontal">
          <Menu.Item
            key="1"
            icon={<NotificationOutlined />}
            onClick={() => history.push(paths.addProductUpdate)}
          >
            Add Product Update
          </Menu.Item>
          <Menu.Item key="2" icon={<LoginOutlined />} onClick={logout}>
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </StyledHeader>
  );
}

// Styled Components
const StyledHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

  .logo {
    margin-left: 8px;
    display: flex;
    width: 120px;

    img {
      width: 100%;
    }
  }

  .ant-menu {
    height: 100%;
  }
`;
