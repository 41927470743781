import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "antd/dist/antd.css";

if (process.env.NODE_ENV !== "development")
{
  Sentry.init({
    dsn: "https://2abb2975aa09435c8f96672a097ea70a@o1356279.ingest.sentry.io/6647618",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}



ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occured.</p>} showDialog>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
