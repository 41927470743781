import { useContext } from "react";

import { adminContext } from "context";
import { AdminPanelRoutes } from "./AdminPanelRoutes";
import { AuthRoutes } from "./AuthRoutes";

export function Routes() {
  const context = useContext(adminContext);

  if (!context || !context.admin) {
    return <AuthRoutes />;
  }

  return <AdminPanelRoutes />;
}
