import { Link } from "react-router-dom";
import moment from "moment";
import { Col, Row, Table, Card, Button, Space, message } from "antd";

import { InviteUsers } from "./InviteUsers";
import { ManageHandler } from "./ManageHandler";
import { SyncStatusBadge } from "./SyncStatusBadge";
import {
  AllConnectedDistirctsQuery,
  District,
  DistrictSyncStatus,
  useAllConnectedDistirctsQuery,
  useStartDistrictSyncMutation,
} from "generated/types";
import { useCallback, useEffect } from "react";

const { Column } = Table;

export function Home() {
  const allConnectedDistrictsQuery = useAllConnectedDistirctsQuery({
    pollInterval: 1000
  });
  
  const [
    startDistrictSyncMutation,
    { loading: startDistrictSyncMutationLoading },
  ] = useStartDistrictSyncMutation({
    onCompleted: () => {
      allConnectedDistrictsQuery.refetch();
    },
    onError: (err) => {
      message.error("Something went wrong please try again");
      console.error(err);
    },
  });

  const handleSyncStart = useCallback(async(district: District) => {
    const confirmation = window.confirm("Please confirm to start sync");

    if (!confirmation) {
      return;
    }

    if (
      allConnectedDistrictsQuery.data &&
      allConnectedDistrictsQuery.data.allConnectedDistricts.some(
        (cachedDistrict) =>
          cachedDistrict._id === district._id &&
          district.syncStatus === "loading"
      )
    ) {
      message.error("District is already in progress");
      return;
    }

    await startDistrictSyncMutation({
      variables: {
        district: {
          _id: district._id,
          name: district.name,
          classlinkId: district.classlinkId,
          cleverId: district.cleverId,
        },
      },
    });
  }, [allConnectedDistrictsQuery.data, startDistrictSyncMutation])  


  useEffect(() => {
    const districts = allConnectedDistrictsQuery.data?.allConnectedDistricts;

    if (districts && !allConnectedDistrictsQuery.error) {
      const hasLoadingDistrict = districts.some(
        (district) => district.syncStatus === DistrictSyncStatus.Loading
      );

      if (hasLoadingDistrict) {
        allConnectedDistrictsQuery.startPolling(1000);
      } else {
        allConnectedDistrictsQuery.stopPolling();
      }
    }
  }, [allConnectedDistrictsQuery, handleSyncStart]);

  return (
    <Row>
      <Col span="24">
        <Card title="Districts">
          <Table
            loading={allConnectedDistrictsQuery.loading}
            bordered
            dataSource={allConnectedDistrictsQuery.data?.allConnectedDistricts}
            pagination={false}
          >
            <Column
              title="#"
              dataIndex="_id"
              key="id"
              render={(_, __, index) => index + 1}
            />
            <Column title="Name" dataIndex="name" key="name" />
            <Column
              title="Sync Status"
              key="syncStatus"
              render={(district) => <SyncStatusBadge district={district} />}
            />
            <Column
              title="Last Synced"
              key="lastSynced"
              dataIndex="lastSynced"
              render={(date) => moment(date).fromNow()}
            />
            <Column
              title="Actions"
              key="actions"
              render={(
                district: AllConnectedDistirctsQuery["allConnectedDistricts"][number]
              ) => (
                <Space>
                  <Button
                    type="primary"
                    disabled={
                      district.syncStatus === DistrictSyncStatus.Loading
                    }
                    onClick={() =>
                      handleSyncStart({ ...district, sectionPeriods: [] })
                    }
                  >
                    Sync
                  </Button>
                  <InviteUsers district={district} />
                  <ManageHandler district={district} />
                  <Link to={`/schools/${district._id}`}>
                    <Button
                      color="link"
                      disabled={
                        district.syncStatus !== DistrictSyncStatus.Synced
                      }
                    >
                      Manage Schools
                    </Button>
                  </Link>
                </Space>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Row>
  );
}
