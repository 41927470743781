import { message, Switch } from "antd";
import { useEnableAskEdToSchoolMutation } from "generated/types";
import styled from "styled-components";

type Props = {
  schoolId: string;
  isAskEdEnabled: boolean;
};

export function EnableAskEdToSchool({ schoolId, isAskEdEnabled }: Props) {
  const [enableAskEdToSchoolMutation, { loading }] =
    useEnableAskEdToSchoolMutation({
      refetchQueries: ["SchoolsManageSchoolsListing"],
      onCompleted: async () => {
        await message.success("Updated successfully!");
      },
      onError: (error) => {
        console.log(error, "EnableAskEd mutation");
        message.error("Error occurred!");
      },
    });

  function handleEnableSwitchChange() {
    enableAskEdToSchoolMutation({
      variables: {
        schoolId,
      },
    });
  }

  return (
    <EnableButtonWrapper>
      Enable AskEd:{" "}
      <Switch
        onChange={handleEnableSwitchChange}
        loading={loading}
        checked={isAskEdEnabled}
      />
    </EnableButtonWrapper>
  );
}

const EnableButtonWrapper = styled.div`
  margin-left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
