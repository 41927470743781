import { useState } from "react";
import "styled-components/macro";
import { Button, Form, Modal, Input, message, Select } from "antd";
import {
  AllConnectedDistirctsQuery,
  DistrictSyncStatus,
  useInviteUserMutation,
  useSchoolsQuery,
} from "generated/types";

type Props = {
  district: AllConnectedDistirctsQuery["allConnectedDistricts"][number];
};

export function InviteUsers({ district }: Props) {
  const [isOpen, setOpen] = useState(false);
  const [form] = Form.useForm<{ email: string; schoolId?: string }>();

  // TODO: useInfiniteQuery. Currently fetching all schools
  const schoolsQuery = useSchoolsQuery({
    variables: { districtId: district._id, filter: { limit: 0 } },
  });

  const [inviteUserMutation, { loading: inviteUserLoading }] =
    useInviteUserMutation({
      onCompleted: () => {
        message.success("Invitation sent");
      },
      onError: (err) => {
        message.error("Failed to send invitation");
        console.error(err);
      },
    });

  function toggle() {
    setOpen((isOpen) => !isOpen);
  }

  async function handleSubmit() {
    const { email, schoolId } = await form.validateFields();

    await inviteUserMutation({
      variables: { input: { email, districtId: district._id, schoolId } },
    });

    form.resetFields();
  }

  return (
    <>
      <Button
        onClick={toggle}
        disabled={district.syncStatus !== DistrictSyncStatus.Synced}
      >
        Invite Users
      </Button>
      <Modal
        title="Invite Users"
        visible={isOpen}
        onCancel={toggle}
        footer={null}
      >
        <Form form={form} onSubmitCapture={handleSubmit} layout="vertical">
          <Form.Item label="Email" name="email">
            <Input placeholder="Email..." />
          </Form.Item>
          <Form.Item label="Select School" name="schoolId">
            <Select
              options={schoolsQuery.data?.districtSchools.map((school) => ({
                label: school.name,
                value: school._id,
              }))}
            />
          </Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={inviteUserLoading}
          >
            Invite
          </Button>
        </Form>
      </Modal>
    </>
  );
}
