import styled from "styled-components";
import { message, Form, Input, Button, Layout, Card } from "antd";
import { AUTH_TOKEN_KEY } from "config/constants";
import Logo from "design/logo.svg";
import { useLoginAdminMutation } from "generated/types";

export function SignIn() {
  const [form] = Form.useForm<{ username: string; password: string }>();

  const [devLoginMutation, { loading }] = useLoginAdminMutation({
    onCompleted: ({ token }) => {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
      message.success({
        key: useLoginAdminMutation.name,
        content: "Successfully logged in",
      });
      window.location.reload();
    },
    onError: (err) => {
      message.error({
        content: err.message,
        key: useLoginAdminMutation.name,
      });
    },
  });

  if (loading) {
    message.loading({
      key: useLoginAdminMutation.name,
      content: "Logging in...",
    });
  }

  async function handleLogIn() {
    const { username, password } = await form.validateFields();

    devLoginMutation({
      variables: {
        input: {
          username,
          password,
        },
      },
    });
  }

  return (
    <Wrapper>
      <Layout>
        <Layout.Content>
          <SignInCardWrapper>
            <Card>
              <LogoStyled>
                <img src={Logo} alt="edclerk-logo" />
              </LogoStyled>
              <h1>Admin Panel Login</h1>
              <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                onFinish={handleLogIn}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: "Username is required",
                    },
                  ]}
                >
                  <Input size="large" autoComplete="off" disabled={loading} />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    autoComplete="off"
                    type="password"
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    block
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </SignInCardWrapper>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.div`
  .ant-layout {
    height: 100vh;
    overflow: auto;
  }

  .ant-layout-header {
    background-color: transparent;
  }

  .ant-input-prefix {
    color: rgb(24, 144, 255);
  }

  main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
`;

const SignInCardWrapper = styled.div`
  width: 400px;

  @media (max-width: 767.98px) {
    width: 100%;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;

const LogoStyled = styled.div`
  width: 200px;
  margin-bottom: 40px;

  img {
    width: 100%;
  }
`;
