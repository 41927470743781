import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { AdminContextProvider } from "context";
import { queryClient } from "utils/queryClient";

import { Routes } from "./routes";

function App() {
  return (
    <ApolloProvider client={queryClient}>
      <BrowserRouter>
        <AdminContextProvider>
          <Routes />
        </AdminContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
