import { useState } from "react";
import { Alert, Button, Modal, Spin } from "antd";
import {
  AllConnectedDistirctsQuery,
  DistrictSyncStatus,
  useDistrictHandlerQuery,
} from "generated/types";

import { Appoint } from "./Appoint";
import { Discharge } from "./Discharge";

type Props = {
  district: AllConnectedDistirctsQuery["allConnectedDistricts"][number];
};

export function ManageHandler({ district }: Props) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const districtHandlerQuery = useDistrictHandlerQuery({
    variables: { districtId: district._id },
  });

  function toggleModal() {
    setModalOpen((state) => !state);
  }

  return (
    <>
      <Button
        onClick={toggleModal}
        disabled={district.syncStatus !== DistrictSyncStatus.Synced}
      >
        Manage Handler
      </Button>
      <Modal
        title="Manage Handler"
        visible={modalOpen}
        onCancel={toggleModal}
        footer={null}
      >
        {(() => {
          if (district.syncStatus === "loading") {
            return (
              <Alert
                type="warning"
                message="District sync is in progress. Please manage handler after sync is finished."
              />
            );
          }

          if (!districtHandlerQuery.data || districtHandlerQuery.loading) {
            return <Spin />;
          }

          if (!districtHandlerQuery.data.districtHandler) {
            return <Appoint districtId={district._id} />;
          }

          return (
            <Discharge
              districtId={district._id}
              districtHandler={districtHandlerQuery.data.districtHandler}
            />
          );
        })()}
      </Modal>
    </>
  );
}
