import { Route, Switch, Redirect } from "react-router-dom";

import { SignIn } from "../pages";

export function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/sign-in">
        <SignIn />
      </Route>
      <Route path="/" component={() => <Redirect to="/sign-in" />} />
    </Switch>
  );
}
