import { useApolloClient } from "@apollo/client";
import { Button, Row, Col, message, Typography } from "antd";
import {
  DistrictHandlerDocument,
  DistrictHandlerQuery,
  useRemoveDistrictHandlerMutation,
} from "generated/types";

type Props = {
  districtId: string;
  districtHandler: NonNullable<DistrictHandlerQuery["districtHandler"]>;
};

export function Discharge({ districtId, districtHandler }: Props) {
  const queryClient = useApolloClient();

  const [removeDistrictHandlerMutation, { loading }] =
    useRemoveDistrictHandlerMutation({
      onCompleted: async () => {
        await queryClient.refetchQueries({
          include: [DistrictHandlerDocument],
        });
        message.success("Successfully removed");
      },
      onError: (err) => {
        message.error("Failed to remove");
        console.error(err);
      },
    });

  function handleClick() {
    removeDistrictHandlerMutation({
      variables: { districtId, districtHandlerId: districtHandler._id },
    });
  }

  return (
    <Row justify="space-between">
      <Col>
        <Typography.Text>
          {districtHandler.lastName} {districtHandler.firstName} -{" "}
          {districtHandler.email}
        </Typography.Text>
      </Col>
      <Col>
        <Button type="ghost" danger loading={loading} onClick={handleClick}>
          Discharge
        </Button>
      </Col>
    </Row>
  );
}
