import React, { useState } from "react";
import { Button, Modal, Checkbox, Form } from "antd";

import { InputBox } from "./InputBox";
import { StudentsFilters } from "generated/types";

type Props = {
  studentsFilters: StudentsFilters;
  onSave: (studentsFilters: StudentsFilters) => void;
};

export function StudentsFiltersModal({
  studentsFilters: initialStudentsFilters,
  onSave,
}: Props) {
  const [isOpen, setOpen] = useState<boolean>(false);

  const [studentsFilters, setStudentFilters] = useState<StudentsFilters>({
    ell: initialStudentsFilters.ell,
    iep: initialStudentsFilters.iep,
    plan504: initialStudentsFilters.plan504,
  });

  const isAllChecked =
    typeof studentsFilters.iep != "number" &&
    typeof studentsFilters.ell != "number" &&
    typeof studentsFilters.plan504 != "number";

  function toggleOpen() {
    setOpen((open) => !open);
  }

  function handleFilterChange(name: string, limit: number | undefined) {
    setStudentFilters((state) => ({
      ...state,
      [name]: limit,
    }));
  }

  function handleAllChange() {
    if (isAllChecked) {
      setStudentFilters({
        ell: 0,
        iep: 0,
        plan504: 0,
      });
    } else {
      setStudentFilters({
        ell: undefined,
        iep: undefined,
        plan504: undefined,
      });
    }
  }

  function handleSave() {
    onSave(studentsFilters);
    toggleOpen();
  }

  return (
    <>
      <Button onClick={toggleOpen}>Students Filters</Button>
      <Modal
        width={400}
        title="Change students accesss"
        visible={isOpen}
        onCancel={toggleOpen}
        footer={false}
      >
        <Form>
          <InputBox
            name="ell"
            limit={studentsFilters.ell}
            disabled={isAllChecked}
            onChange={handleFilterChange}
          />

          <InputBox
            name="iep"
            limit={studentsFilters.iep}
            disabled={isAllChecked}
            onChange={handleFilterChange}
          />
          <InputBox
            name="plan504"
            limit={studentsFilters.plan504}
            disabled={isAllChecked}
            onChange={handleFilterChange}
          />
          <Form.Item>
            <Checkbox
              id="all"
              type="checkbox"
              checked={isAllChecked}
              onChange={handleAllChange}
            >
              ALL
            </Checkbox>
          </Form.Item>

          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Form>
      </Modal>
    </>
  );
}
