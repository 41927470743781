import { useApolloClient } from "@apollo/client";
import { message, Form, Input, Button } from "antd";
import {
  DistrictHandlerDocument,
  useAddDistrictHandlerMutation,
} from "generated/types";

type Props = {
  districtId: string;
};

export function Appoint({ districtId }: Props) {
  const queryClient = useApolloClient();
  const [form] = Form.useForm<{ userEmail: string }>();

  const [addDistrictHandlerMutation, { loading }] =
    useAddDistrictHandlerMutation({
      onCompleted: async () => {
        await queryClient.refetchQueries({
          include: [DistrictHandlerDocument],
        });
        message.success("Successfully added");
      },
      onError: (err) => {
        message.error("Failed to add");
        console.error(err);
      },
    });

  async function handleSubmit() {
    const { userEmail } = await form.validateFields();

    await addDistrictHandlerMutation({
      variables: { districtId, userEmail },
    });

    form.resetFields();
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item label="Email" name="userEmail">
        <Input placeholder="Email" />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Appoint
      </Button>
    </Form>
  );
}
