import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { Layout } from "antd";

import { AddProductUpdate, Header } from "../components";
import { Home, SchoolsManage } from "../pages";
import { paths } from "../utils/paths";

const { Content } = Layout;

export function AdminPanelRoutes() {
  const location = useLocation();

  return (
    <Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <Header />
        <Content style={{ margin: "24px" }}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/schools/:districtId">
              <SchoolsManage />
            </Route>
            <Route path="*" exact>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      {
        // TODO: Might be better solution to show modal on route
        location.hash === paths.addProductUpdate && <AddProductUpdate />
      }
    </Layout>
  );
}
