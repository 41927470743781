import { useParams } from "react-router-dom";
import { Col, Row, Card, message, List, Typography, Input } from "antd";
import {
  StudentsFilters,
  useSchoolsManageSchoolsListingQuery,
  useSetStudentsFiltersMutation,
} from "generated/types";

import { StudentsFiltersModal } from "./StudentsFiltersModal";
import SemestersManageModal from "./SemestersManageModal";
import styled from "styled-components";
import { useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { EnableAskEdToSchool } from "./EnableAskEd";

export function SchoolsManage() {
  const { districtId } = useParams<{ districtId: string }>();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const schoolsQuery = useSchoolsManageSchoolsListingQuery({
    variables: {
      districtId,
      filter: {
        textSearch: searchTerm,
        limit: 30,
      },
    },
  });

  const [setStudentsFiltersMutation, { loading: mutationLoading }] =
    useSetStudentsFiltersMutation({
      onCompleted: () => {
        message.success({
          key: useSetStudentsFiltersMutation.name,
          content: "Successfully Updated!",
        });
      },
      onError: (error) => {
        message.error({
          key: useSetStudentsFiltersMutation.name,
          content: "Something went wrong!",
        });
        console.error(error);
      },
    });

  if (mutationLoading) {
    message.loading({
      key: useSetStudentsFiltersMutation.name,
      content: "Loading...",
    });
  }

  function saveStudentsFilters(
    schoolId: string,
    studentsFilters: StudentsFilters
  ) {
    setStudentsFiltersMutation({
      variables: {
        schoolId,
        studentsFilters,
      },
    });
  }

  function handleSearch (e: React.ChangeEvent<HTMLInputElement>){
    setSearchTerm(e.target.value);
  }

  const handleDebouncedSearch = useDebounce(handleSearch, 1500);

  return (
    <Row>
      <Col span="24">
        <Card
          title="Manage Schools"
          extra={
            <Input
              placeholder="Search Schools"
              onChange={handleDebouncedSearch}
            />
          }
        >
          <List loading={schoolsQuery.loading}>
            {schoolsQuery.data?.districtSchools.map((school) => (
              <List.Item
                extra={
                  <SchoolButtonsWrapper>
                    <StudentsFiltersModal
                      studentsFilters={school.studentsFilters}
                      onSave={(studentsFilters) =>
                        saveStudentsFilters(school._id, studentsFilters)
                      }
                    />
                    <EnableAskEdToSchool isAskEdEnabled={school.isAskEdEnabled} schoolId={school._id} />
                    {/* <SemestersManageModal schoolId={school._id} /> */}
                  </SchoolButtonsWrapper>
                }
              >
                <Typography.Text>{school.name}</Typography.Text>
              </List.Item>
            ))}
          </List>
        </Card>
      </Col>
    </Row>
  );
}

const SchoolButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
