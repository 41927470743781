import React from "react";
import styled from "styled-components";

import { Input, Checkbox, Space, Form } from "antd";

const Wrapper = styled.div`
  .ant-space {
    justify-content: space-between;
    width: 100%;
  }
`;

type Props = {
  name: "iep" | "ell" | "plan504";
  limit?: number | undefined | null;
  disabled: boolean;
  onChange: (name: string, limit: undefined | number) => void;
};

export function InputBox({ name, disabled, limit, onChange }: Props) {
  const checked = typeof limit == "number";

  function handleCheckChange() {
    onChange(name, checked ? undefined : 0);
  }

  function handleLimitChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    const parsedValue = parseInt(value);

    onChange(name, !isNaN(parsedValue) ? parsedValue : 0);
  }

  return (
    <Wrapper>
      <Form.Item>
        <Space split>
          <Checkbox
            id={name}
            name={name}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={handleCheckChange}
          >
            {name.toUpperCase()}
          </Checkbox>
          {checked && !disabled ? (
            <Input
              type="text"
              placeholder="Limit"
              value={limit}
              onChange={handleLimitChange}
            />
          ) : undefined}
        </Space>
      </Form.Item>
    </Wrapper>
  );
}
