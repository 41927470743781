import { Tooltip } from "antd";
import { Tag as AntdTag, Progress } from "antd";
import { District, DistrictSyncStatus } from "generated/types";

type Props = {
  district: District;
};

export function Tag({ district }: Props) {
  function getColorAndText() {
    let color, text;

    if (district.syncStatus === DistrictSyncStatus.Synced) {
      color = "green";
      text = "Synced";
    } else if (district.syncStatus === DistrictSyncStatus.Failed) {
      color = "red";
      text = "Failed";
    } else {
      color = "";
      text =
        district.syncStatus === DistrictSyncStatus.Loading
          ? "Loading"
          : "Not synced";
    }

    return { color, text };
  }

  function getTooltipTitle() {
    if (district.syncStatus === "failed") {
      return `Message: ${district.syncFailureMessage || "N/A"}`;
    }

    if (district.syncStatus === "loading") {
      return `Loading...`;
    }

    return "";
  }

  return (
    <Tooltip overlay={getTooltipTitle()}>
      {(() => {
        return (
          <AntdTag color={getColorAndText().color}>
            {getColorAndText().text}
          </AntdTag>
        );
      })()}
    </Tooltip>
  );
}
