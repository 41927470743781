import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { AUTH_TOKEN_KEY } from "config/constants";

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.error(message));
});

const httpLink = new HttpLink({
  uri: (() => {
    return window.location.host !== "localhost:3000"
      ? window.location.host === "admin-panel.qa.edclerk.com"
        ? "https://app.qa.edclerk.com/graphql"
        : "https://app.edclerk.com/graphql"
      : "http://localhost:8080/graphql";
  })(),
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const userToken = localStorage.getItem(AUTH_TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: userToken ? `Bearer ${userToken}` : "",
    },
  };
});

export const queryClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
